<template>
  <div class="hello">
    
    <h1>送样手册</h1>
    <div class="box">
    <a-input-search placeholder="input search text" style="width: 370px;margin-bottom:10px;" v-model="search"/>
    <a-button class="btn" v-for="item in getSearch"  :key="item.id" @click="zhixing(item.btn)" :style="{height:item.name === '硬聚氬乙烯(UPVC),氯化聚氯乙烯(CPVC)塑料电缆导管(实壁类塑料电缆导管)'?'60px':'32px'}">{{item.name}}</a-button>
    <p>@2024 szapzl.com 版权所有 苏ICP备2024119886号-1</p>
    <a-modal v-model="modal1" title='胶粉聚苯颗粒保温砂浆' :footer="null" centered >
      <p>类别：BJ</p>
      <p>检测参数：干表观密度&nbsp;&nbsp;抗压强度&nbsp;&nbsp;导热系数&nbsp;&nbsp;粘结强度</p>
      <p>样品规格：300*300*30mm=4块<br>100*100*100mm=12块<br>成型28天后可送检<br>附质保书复印件</p>
      <p>检测周期:30个工作日</p>
      <p>检测费用:1600</p>
    </a-modal>
    <a-modal v-model="modal2" title='保温砂浆' :footer="null" centered >
      <p>类别：BJ</p>
      <p>检测参数：表观密度&nbsp;&nbsp;抗压强度&nbsp;&nbsp;导热系数</p>
      <p>样品规格：300*300*30mm=2块<br>70.7*70.7*70.7mm=6块<br>成型28天后可送检<br>附质保书复印件</p>
      <p>检测周期:30个工作日</p>
      <p>检测费用:1600</p>
    </a-modal>
    <a-modal v-model="modal3" title='幕墙玻璃' :footer="null" centered >
      <p>类别：BL</p>
      <p>检测参数：可见光透射比&nbsp;&nbsp;遮阳系数&nbsp;&nbsp;露点&nbsp;&nbsp;传热系数</p>
      <p>样品规格：300*300mm=3块(中空非钢化)<br>510*360mm=15块(中空钢化)<br>送检时提供相关:<br>1.设计图纸复印件<br>2.节能计算书复印件<br>以上两份材料,见证员盖章签字</p>
      <p>检测费用:14000</p>
    </a-modal>
    <a-modal v-model="modal4" title='现场-建筑保温系统构造钻芯' :footer="null" centered >
      <p>类别：BX</p>
      <p>检测参数：保温层构造厚度</p>
      <p>检测费用:1800/组</p>
    </a-modal>
    <a-modal v-model="modal5" title='外墙节能构造钻芯' :footer="null" centered >
      <p>类别：BX</p>
      <p>检测参数：保温层构造</p>
      <p>检测费用:1800</p>
    </a-modal>
    <a-modal v-model="modal6" title='承载率比' :footer="null" centered >
      <p>类别：CB</p>
      <p>样品规格：120斤</p>
      <p>检测费用:1060</p>
    </a-modal>
    <a-modal v-model="modal7" title='现场-建筑变形' :footer="null" centered >
      <p>类别：CJ</p>
      <p>检测参数：沉降观测，位移观测</p>
      <p>代表数量：合同确定</p>
      <p>检测费用:100/点次，按实际点次结算:每布一点另加120元材料埋设费</p>
    </a-modal>
    <a-modal v-model="modal8" title='现场-混凝土强度(回弹)' :footer="null" centered >
      <p>类别：HT</p>
      <p>检测参数：回弹法测强度</p>
      <p>代表数量：合同确定</p>
      <p>检测费用: 30/测区(300/点),按实际点数结算</p>
    </a-modal>
    <a-modal v-model="modal9" title='粗集料(基层用)' :footer="null" centered >
      <p>类别：CS</p>
      <p>检测参数：筛分&nbsp;&nbsp;压碎值&nbsp;&nbsp;针片状</p>
      <p>样品规格：50kg/份,要做压碎值的材料数量加倍</p>
      <p>代表数量：改600t或400m³或按进场批次实际量</p>
      <p>检测费用: 筛分220，压碎值220，针片状(基层用)260</p>
    </a-modal>
    <a-modal v-model="modal10" title='击实(无黏聚性粗巨粒土)' :footer="null" centered >
      <p>类别：CT</p>
      <p>检测参数：粗、巨粒士最大干密度<br>注意:级配碎石需提供配合比(无配合比需做市政筛分)</p>
      <p>样品规格：级配砂石:砂30斤，碎石80斤<br>级配碎石:每种碎石各80斤，每种碎石各做一个筛分析，提供图纸上的筛分表</p>
      <p>检测费用: 粗粒土2000</p>
    </a-modal>
    <a-modal v-model="modal11" title='土(建筑材料放射性)' :footer="null" centered >
      <p>类别：CW</p>
      <p>检测参数：内照射指数<br>外照射指数</p>
      <p>样品规格：5斤</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal12" title='大理石(建筑材料放射性)' :footer="null" centered >
      <p>类别：CW</p>
      <p>检测参数：内照射指数<br>外照射指数</p>
      <p>样品规格：400*400mm=2块</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal13" title='石膏板(建筑材料放射性)' :footer="null" centered >
      <p>类别：CW</p>
      <p>检测参数：内照射指数<br>外照射指数</p>
      <p>样品规格：400*400mm=2块</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal14" title='砖瓦(建筑材料放射性)' :footer="null" centered >
      <p>类别：CW</p>
      <p>检测参数：内照射指数<br>外照射指数</p>
      <p>样品规格：2整块</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal15" title='无侧限抗压' :footer="null" centered >
      <p>类别：CX</p>
      <p>检测参数：无侧限抗压强度(7天)</p>
      <p>样品规格：<br>混合料:<br>灰士:5斤<br>泥灰碎石/二灰碎石/水泥稳定碎石:180斤<br>原材料:<br>
        灰土:石灰5斤，土10斤<br>二灰碎石:石灰50斤，粉煤灰50斤，每种碎石各80斤<br>泥灰碎石:石灰50斤，土50斤，每种碎石各80斤<br>水泥稳定碎石:水泥10斤，每种碎石各80斤</p>
      <p>检测费用: 细粒料300,粗粒料1300</p>
    </a-modal>
    <a-modal v-model="modal16" title='插座' :footer="null" centered >
      <p>类别：CZ</p>
      <p>检测参数：拔出插头所需的力、防潮、机械强度、绝缘电阻和电气强度正常操作</p>
      <p>样品规格：9个<br>写明型号+规格</p>
      <p>检测费用: 300</p>
    </a-modal>
    <a-modal v-model="modal17" title='电工套管KBG/JDG' :footer="null" centered >
      <p>类别：DT</p>
      <p>检测参数：冲击试验<br>压力试验（卸荷和载荷）</p>
      <p>样品规格：6根<br>每根2m</p>
      <p>检测周期:10个工作日</p>
      <p>检测费用: 520</p>
    </a-modal>
    <a-modal v-model="modal18" title='电工套管PVC' :footer="null" centered >
      <p>类别：DT</p>
      <p>检测参数：绝缘强度<br>抗压性能(卸荷)<br>抗压性能(载荷)<br>耐热性能<br>弯扁性能<br>弯曲性能<br>自熄时间</p>
      <p>样品规格：6根<br>每根2m</p>
      <p>检测周期:10个工作日</p>
      <p>检测费用: 700</p>
    </a-modal>
    <a-modal v-model="modal19" title='电工套管(金属电缆导管)' :footer="null" centered >
      <p>类别：DT</p>
      <p>检测参数：冲击试验<br>压力试验(载荷和卸荷)</p>
      <p>样品规格：6根<br>每根2m</p>
      <p>检测周期:10个工作日</p>
      <p>检测费用: 520</p>
    </a-modal>
    <a-modal v-model="modal20" title='电缆' :footer="null" centered >
      <p>类别：DX</p>
      <p>检测参数：导体电阻<br>电压试验<br>绝缘层老化前断裂伸长率<br>绝缘层老化前后断裂伸长率变化率<br>绝缘层老化前后抗张强度变化率
      <br>绝缘层老化前抗张强度<br>绝缘厚度<br>绝缘最薄点厚度<br>外径</p>
      <p>样品规格：≥4米，带好合格证(常规电缆，3kV及以下)</p>
      <p>代表数量: 多芯10000米/组,单芯20000米/组</p>
      <p>检测周期:10个工作日</p>
      <p>检测费用: 常规电缆1000/芯,特殊电缆待定</p>
    </a-modal>
    <a-modal v-model="modal21" title='电线' :footer="null" centered >
      <p>类别：DX</p>
      <p>检测参数：导体电阻<br>电压试验<br>绝缘层老化前断裂伸长率<br>绝缘层老化前后断裂伸长率变化率<br>绝缘层老化前后抗张强度变化率
      <br>绝缘层老化前抗张强度<br>绝缘厚度<br>绝缘最薄点厚度<br>外径</p>
      <p>样品规格：附合格证整包装一份</p>
      <p>代表数量: 同一厂家、同一规格型号，每20000m抽样不少于一组</p>
      <p>检测周期:10个工作日</p>
      <p>检测费用: 1030/芯</p>
    </a-modal>
    <a-modal v-model="modal22" title='自粘聚合物改性沥青防水卷材' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：<br>N类:拉力(D型不做)、最大拉力时延伸率(D型不做)、沥青断力时延伸率、不透水性、低温柔性<br>
PY类:拉力、沥青断力时延伸率、不透水性、低温柔性、耐热性</p>
      <p>样品规格: 2㎡</p>
      <p>代表数量: 10000㎡</p>
      <p>检测费用: N类(PE、PET):3150元;PY类(I型、II型):3060元</p>
    </a-modal>
    <a-modal v-model="modal23" title='预铺/湿铺防水卷材' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：<br>预铺P和R类:拉力、膜断裂伸长率、耐热性、低温弯折性、不透水性<br>
        预铺PY类:拉力、最大拉力时伸长率、耐热性低温柔性、不透水性<br>
        湿铺:拉力、最大拉力时伸长率、耐热性、低温柔性、不透水性</p>
      <p>样品规格: 2㎡</p>
      <p>代表数量: 10000㎡</p>
      <p>检测费用: 预铺防水卷材(P类):900元:预铺防水卷材(PY类):610元/地下3060元;预铺防水卷材(R类):870元;湿铺防水卷材(H类、E类、PY类):610元/地下3060元。</p>
    </a-modal>
    <a-modal v-model="modal24" title='聚合物乳液建筑防水涂料' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：固体含量、表干时间、实干时间、低温弯折性、不透水性、拉伸强度、断裂伸长率</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶<br>附质保书复印件</p>
      <p>代表数量: 5t</p>
      <p>检测费用: 650</p>
    </a-modal>
    <a-modal v-model="modal25" title='聚氯乙烯防水卷材' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：最大拉力(拉伸强度)<br>最大拉力时伸长率(断裂伸长率)<br>低温弯折性<br>不透水性<br>撕裂强度</p>
      <p>样品规格: 2㎡<br>附质保书复印件</p>
      <p>代表数量: 10000㎡</p>
      <p>检测费用: H型、G型:700元;L型、GL型:580元;P型:580元;T类(O、M、P、R)S类(M):3060元。</p>
    </a-modal>
    <a-modal v-model="modal26" title='弹/塑性体改性沥青防水卷材' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：不透水性、拉力、最大拉力时延伸率、沥青断裂延伸率、低温柔性、热老化低温柔性</p>
      <p>样品规格: 2㎡</p>
      <p>代表数量: 10000㎡</p>
      <p>检测费用: 弹性体(I型PY、Ⅱ型PY、Ⅱ型PYG):610/地下3060元;弹性体(I型G、I型G):520/地下2970元;塑性体(I型PY、I型PY、I型PYG):610/地下3060元;塑性体(I型G、Ⅱ型G):520/地下2970元。</p>
    </a-modal>
    <a-modal v-model="modal27" title='聚氨酯防水涂料' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：表干时间<br>实干时间<br>不透水性<br>低温柔性<br>拉伸强度<br>断裂伸长率</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶<br>附质保书复印件</p>
      <p>代表数量: 15t</p>
      <p>检测费用: 1170</p>
    </a-modal>
    <a-modal v-model="modal28" title='防水卷材' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：不透水性<br>拉力<br>最大拉力时延伸率<br>沥青断裂延伸率<br>低温柔性<br>热老化低温柔性</p>
      <p>样品规格: 最少2㎡&nbsp;&nbsp;附质保书复印件</p>
      <p>代表数量: 高分子防水(片材):5000㎡<br>弹性体改性沥青防水卷材:10000㎡<br>塑性体改性沥青防水卷材:10000㎡<br>胶粉改性沥青玻纤毡与玻纤网格布增强防水卷材:10000㎡<br>
        胶粉改性沥青玻纤毡与聚乙烯腊增强防水卷材:10000㎡<br>胶粉改性沥青聚酯毡下玻纤网格布增强防水卷材:10000㎡<br>自粘聚合物改性沥青防水卷材:10000㎡<br>湿铺防水卷材:10000㎡<br>预铺防水卷材:10000㎡<br>带自粘层的防水卷材:相关标准要求<br>
        种植屋面用耐根穿刺防水卷材:相关标准I型要求<br>聚氯乙烯防水卷材:10000㎡<br>氯化聚乙烯防水卷材:10000㎡<br></p>
      <p>检测费用: 依检测标准变动</p>
    </a-modal>
    <a-modal v-model="modal29" title='高分子防水材料' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：不透水性<br>低温弯折<br>拉断伸长率<br>拉伸强度<br>撕裂强度</p>
      <p>样品规格: 2㎡</p>
      <p>代表数量: 8000</p>
      <p>检测费用: 均质片、复合片:700元;自粘片:840元;异型片:240元</p>
    </a-modal>
    <a-modal v-model="modal30" title='聚合物水泥防水涂料' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：不透水性<br>断裂伸长率<br>拉伸强度<br>低温柔性<br>固体含量</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶<br>附质保书复印件</p>
      <p>代表数量: 10t</p>
      <p>检测费用: I型:700元;Ⅱ型、Ⅲ型:580元</p>
    </a-modal>
    <a-modal v-model="modal31" title='耐根穿刺防水卷材' :footer="null" centered >
      <p>类别：FS</p>
      <p>检测参数：<br>弹/塑性体改性沥青防水卷材、改性聚氯乙烯胎防水卷材:不透水性、拉力、最大拉力时延伸率、低温柔性、热老化低温柔性<br>
        聚氯乙烯防水卷材:<br>拉伸强度、断裂伸长率、不透水性、低温弯折性、真角撕裂强度<br>高分子防水材料:<br>不透水性、
        低温弯折、拉断伸长率、拉伸强度、撕裂强度</p>
      <p>样品规格: 2㎡<br>附质保书复印件</p>
      <p>代表数量: 10000㎡</p>
      <p>检测费用: 弹/塑性体改性沥青防水卷材、改性聚氯乙烯胎防水卷材:3060元;聚氯乙烯防水卷材:H型、G型:700元;L型、GL型:580元:P型:580元高分子防水材料 均质片、复合片:700元;自片:840元;异型片:240元</p>
    </a-modal>
    <a-modal v-model="modal32" title='现场-结构构件' :footer="null" centered >
      <p>类别：GB</p>
      <p>检测参数：钢筋位置<br>板厚<br>保护层厚度<br>回弹法测强度</p>
      <p>检测费用: 钢筋位置100元/点、板厚500元/块、保护层厚度50元/点、回弹法测强度300元/构件，按实际点数结算</p>
    </a-modal>
    <a-modal v-model="modal33" title='UPVC螺旋消音管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：壁厚<br>拉伸屈服强度<br>落锤冲击<br>外径<br>维卡软化温度<br>纵向回缩率</p>
      <p>样品规格：3根1米</p>
      <p>检测费用: 1370</p>
    </a-modal>
    <a-modal v-model="modal34" title='建筑排水用硬聚氩乙烯管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>拉伸试验<br>落锤冲击<br>维卡软化温度<br>纵向回缩率</p>
      <p>样品规格：见实体手册要求</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1370</p>
    </a-modal>
    <a-modal v-model="modal35" title='玻璃纤维电缆管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外观<br>壁厚<br>拉伸试验<br>落锤冲击<br>环刚度</p>
      <p>样品规格：3段30cm</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1170</p>
    </a-modal>
    <a-modal v-model="modal36" title='给水用聚乙烯(PE)管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>纵向回缩率<br>环刚度</p>
      <p>样品规格：3段1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 770</p>
    </a-modal>
    <a-modal v-model="modal37" title='埋地排水用钢带增强聚乙烯(PE)螺旋波纹管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：环刚度、冲击试验</p>
      <p>样品规格：3段30cm、3段20m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1170</p>
    </a-modal>
    <a-modal v-model="modal38" title='PVC-U双壁波纹管材' :footer="null" centered >
      <p>类别：GD</p>
      <p>检测参数：外观及规格尺寸<br>落锤冲击<br>环刚度<br>烘箱试验</p>
      <p>样品规格：9段30cm，3段20cm</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1820</p>
    </a-modal>
    <a-modal v-model="modal39" title='高密度聚乙烯硅芯管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：壁厚<br>外径<br>断裂伸长率<br>拉伸试验<br>纵向回缩率<br>环刚度</p>
      <p>样品规格：3段1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1170</p>
    </a-modal>
    <a-modal v-model="modal40" title='聚乙烯塑钢缠绕排水管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：平均内径<br>冲击性能<br>烘箱试验<br>环刚度<br>环柔性</p>
      <p>样品规格：3段30cm<br>2段1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1820</p>
    </a-modal>
    <a-modal v-model="modal41" title='硬聚氬乙烯(UPVC),氯化聚氯乙烯(CPVC)塑料电缆导管(实壁类塑料电缆导管)' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>落锤冲击<br>维卡软化温度<br>纵向回缩率<br>环刚度</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>
环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1590</p>
    </a-modal>
    <a-modal v-model="modal42" title='HDPE缠绕结构壁管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：平均内径<br>冲击性能<br>烘箱试验<br>环刚度<br>接缝的拉伸强度<br>外观</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 2220</p>
    </a-modal>
    <a-modal v-model="modal43" title='地下通信管道用塑料实壁管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>拉伸试验<br>落锤冲击<br>维卡软化温度<br>纵向回缩率<br>环刚度</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1920</p>
    </a-modal>
    <a-modal v-model="modal44" title='给水用抗冲改性聚氩乙烯管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：落锤冲击<br>维卡软化温度<br>纵向回缩率</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 850</p>
    </a-modal>
    <a-modal v-model="modal45" title='埋地通信用多孔一体塑料管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>拉伸试验<br>落锤冲击<br>维卡软化温度<br>纵向回缩率</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1370</p>
    </a-modal>
    <a-modal v-model="modal46" title='MPP电力管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：内径<br>壁厚<br>拉伸试验<br>落锤冲击<br>维卡软化温度<br>环刚度<br>压扁试验</p>
      <p>样品规格：6段30cm<br>4段1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1890</p>
    </a-modal>
    <a-modal v-model="modal47" title='钢丝网骨架塑料复合管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外观<br>壁厚<br>外径<br>静液压强度</p>
      <p>样品规格：3段10cm<br>2段1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 190</p>
    </a-modal>
    <a-modal v-model="modal48" title='建筑用硬聚氩乙烯雨落水管材及管件' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>拉伸试验<br>维卡软化温度<br>纵向回缩率</p>
      <p>样品规格：3根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1370</p>
    </a-modal>
    <a-modal v-model="modal49" title='无压埋地排污、排水用硬聚氯乙烯管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>落锤冲击<br>环刚度<br>纵向回缩率</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1270</p>
    </a-modal>
    <a-modal v-model="modal50" title='HDPE双壁波纹管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：平均内径<br>冲击性能<br>烘箱试验<br>环刚度<br>外观</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 2220</p>
    </a-modal>
    <a-modal v-model="modal51" title='非开挖铺设用高密度聚乙烯排水管' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>拉伸试验<br>落锤冲击<br>纵向回缩率<br>环刚度</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 2170</p>
    </a-modal>
    <a-modal v-model="modal52" title='建筑排水用高密度聚乙烯(HDPE)管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：外径<br>壁厚<br>纵向回缩率</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 220</p>
    </a-modal>
    <a-modal v-model="modal53" title='双层轴向中空壁管材' :footer="null" centered >
      <p>类别：GC</p>
      <p>检测参数：平均内径<br>冲击性能<br>烘箱试验<br>环刚度</p>
      <p>样品规格：外观及规格尺寸:300mmx3段<br>落锤冲击(简支梁冲击试验):200mmx3段<br>环刚度:300mmx3段<br>烘箱试验:300mmx3段<br>纵向回缩率:200mmx3段<br>维卡软化温度:300mmx3段<br>若有其他试验，则另外附加三根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1820</p>
    </a-modal>
    <a-modal v-model="modal54" title='灌浆套筒连接接头' :footer="null" centered >
      <p>类别：GG</p>
      <p>检测参数：连接件拉伸<br>残余变形</p>
      <p>样品规格：<br>3个套筒，总长50-55cm(工艺检测样品除接头外每端留30cm钢筋)</p>
      <p>代表数量：1000个</p>
      <p>检测周期：到龄期后5个工作日</p>
      <p>检测费用: 普通检测200，工艺检测2000</p>
    </a-modal>
    <a-modal v-model="modal55" title='机械连接' :footer="null" centered >
      <p>类别：GG</p>
      <p>检测参数：拉伸</p>
      <p>样品规格：3个，总长50-55cm</p>
      <p>代表数量：500个</p>
      <p>检测周期：5个工作日</p>
      <p>检测费用: 120(φ≤25mm)、130(φ=28mm)、200(φ≥32mm),工艺检测900</p>
    </a-modal>
    <a-modal v-model="modal56" title='热轧光圆钢筋' :footer="null" centered >
      <p>类别：GJ</p>
      <p>检测参数：屈服点<br>抗拉强度<br>断后伸长率<br>最大力下总伸长率<br>弯曲性能<br>重量偏差</p>
      <p>样品规格：510mmx7根，320mmx2根,两端切平，不打磨<br>送样时完整填写炉批号与代表数量
      <br>存在调直情况时，注明"调直无延伸"|"调直有延伸"</p>
      <p>代表数量：调直无延伸60t，调直有延伸30t</p>
      <p>检测周期：5个工作日</p>
      <p>检测费用: 125(φ≤25mm)、160(φ=28mm)、250(φ≥32mm),复试收费双倍</p>
    </a-modal>
    <a-modal v-model="modal57" title='热轧带肋钢筋' :footer="null" centered >
      <p>类别：GJ</p>
      <p>检测参数：屈服点<br>抗拉强度<br>断后伸长率<br>最大力下总伸长率<br>弯曲性能<br>重量偏差</p>
      <p>样品规格:抗震(带E)510mmx7根700mmx1根<br>普通(不带E)510mmx7根，320mmx2根</p>
      <p>代表数量：60t</p>
      <p>检测周期：5个工作日</p>
      <p>检测费用: 125(φ≤25mm)、160(φ=28mm)、250(φ≥32mm),带E:525(φ≤25mm),560(Ф=28mm)，650(φ≥32mm);复试收费双倍。</p>
    </a-modal>
    <a-modal v-model="modal58" title='热轧带肋钢筋(调直)' :footer="null" centered >
      <p>类别：GJ</p>
      <p>检测参数：屈服点<br>抗拉强度<br>断后伸长率<br>最大力下总伸长率<br>弯曲性能<br>重量偏差</p>
      <p>样品规格：抗震(带E)510mmx7根，700mmx1根<br>普通(不带E)510mmx7根，320mmx2根</p>
      <p>代表数量：30t</p>
      <p>检测周期：5个工作日</p>
      <p>检测费用: 125(φ≤25mm)、160(φ=28mm)、250(φ≥32mm),带E:525(φ≤25mm),560(Ф=28mm)，650(φ≥32mm);复试收费双倍。</p>
    </a-modal>
    <a-modal v-model="modal59" title='钢管、扣件(脚手架用)' :footer="null" centered >
      <p>类别：GK</p>
      <p>检测参数：抗滑<br>抗拉<br>旋转角<br>抗破坏</p>
      <p>样品规格：48管60cm和130cm各一根<br>60管60cm和160cm各一根<br>扣件3种规格每样12个(共36个)<br>单工程最少送一组</p>
      <p>代表数量：30t</p>
      <p>检测费用: 扣件应收3750(其中旋转1000，直角1750、对接1000)，钢管250</p>
    </a-modal>
    <a-modal v-model="modal60" title='电弧焊' :footer="null" centered >
      <p>类别：GL</p>
      <p>检测参数：拉伸</p>
      <p>样品规格：3个,总长不超55cm</p>
      <p>代表数量：300个</p>
      <p>检测费用: 70(Ф≤25mm)，130(φ=28mm),200(φ≥32mm);双倍复试双倍收费</p>
    </a-modal>
    <a-modal v-model="modal61" title='闪光对焊' :footer="null" centered >
      <p>类别：GL</p>
      <p>检测参数：拉伸<br>弯曲</p>
      <p>样品规格：拉伸、弯曲试验各3个<br>不小于60cm</p>
      <p>检测周期：5个工作日</p>
      <p>检测费用: 70(Ф≤25mm)，130(φ=28mm),200(φ≥32mm);双倍复试双倍收费</p>
    </a-modal>
    <a-modal v-model="modal62" title='电渣压力焊' :footer="null" centered >
      <p>类别：GL</p>
      <p>检测参数：拉伸</p>
      <p>样品规格：3个,总长不超55cm</p>
      <p>检测周期：5个工作日</p>
      <p>检测费用: 70(Ф≤25mm)，130(φ=28mm),200(φ≥32mm);双倍复试双倍收费</p>
    </a-modal>
    <a-modal v-model="modal63" title='预埋件' :footer="null" centered >
      <p>类别：GL</p>
      <p>检测参数：预埋件铁板:3cm宽<br>1根50cm<br>1根40cm<br>做拉伸、冷弯试验<br>(铁板单独受理委托,GJ)
      <br>预埋件:60x60mm的铁板中间单边焊接一根钢筋(钢筋长度25cm左右(3个一组)，委托时写明焊工名、焊工证号、钢筋牌号、钢筋规格、焊接方法(具体见下图))。可检范围:φ6-28</p>
      <img src="../assets/img1.jpg">
      <p>代表数量：300个</p>
      <p>检测费用: 90</p>
    </a-modal>
    <a-modal v-model="modal64" title='钢筋网片' :footer="null" centered >
      <p>类别：GP</p>
      <p>检测参数：抗剪力</p>
      <p>样品规格: 加工成'十'字型,三件<br>上端5CM左右<br>下端20CM左右<br>左右两端各3CM左右</p>
      <img src="../assets/img2.jpg">
      <p>检测费用: 300</p>
    </a-modal>
    <a-modal v-model="modal65" title='镀锌管' :footer="null" centered >
      <p>类别：GA</p>
      <p>检测参数：拉伸试验<br>伸长率<br>压扁试验(大于60.3做此项)<br>弯曲试验(小于60.3做此项)</p>
      <p>样品规格：直径大于40mm拉伸试验需裁剪钢板状样品(一-根500*30mm)，详细剪裁要求咨询我司前台</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 720</p>
    </a-modal>
    <a-modal v-model="modal66" title='给水用硬聚氯乙烯管材' :footer="null" centered >
      <p>类别：GS</p>
      <p>检测参数：外观<br>外径<br>壁厚<br>纵向回缩率<br>落锤冲击</p>
      <p>样品规格：3根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 720</p>
    </a-modal>
    <a-modal v-model="modal67" title='冷热水用聚丙烯(PP-R)管材' :footer="null" centered >
      <p>类别：GS</p>
      <p>检测参数：外观<br>外径<br>壁厚<br>纵向回缩率<br>落锤冲击<br>液压试验</p>
      <p>样品规格：3根1m</p>
      <p>检测周期：15个工作日</p>
      <p>检测费用: 1420</p>
    </a-modal>
    <a-modal v-model="modal68" title='钢结构防火/防腐涂料 厚度' :footer="null" centered >
      <p>类别：GT</p>
      <p>检测参数：涂层厚度</p>
      <p>样品规格：现场检测</p>
      <p>检测费用: 30元/点且单组结算价不低于1000元</p>
    </a-modal>
    <a-modal v-model="modal69" title='室内空气(有害物质)' :footer="null" centered >
      <p>类别：HC</p>
      <p>检测参数：TVOC<br>苯浓度<br>氨浓度<br>甲醛<br>室内氢浓度<br>甲苯<br>二甲苯</p>
      <p>样品规格：1000m³</p>
      <p>检测费用: 1600元/点</p>
    </a-modal>
    <a-modal v-model="modal70" title='灰剂量' :footer="null" centered >
      <p>类别：HJ</p>
      <p>检测参数：石灰剂量(灰土)<br>水泥剂量(水泥稳定碎石)</p>
      <p>样品规格：混合料1kg/份</p>
      <p>检测费用: 细粒150、粗粒250</p>
    </a-modal>
    <a-modal v-model="modal71" title='混凝土试块抗压' :footer="null" centered >
      <p>类别：HN</p>
      <p>检测参数：抗压强度</p>
      <p>样品规格：3块/组</p>
      <img src="../assets/img3.jpg">
      <p>代表数量: 100m³/组(标养),由监理确认(同条件)</p>
      <p>检测周期: 到检测龄期后5个工作日</p>
      <p>检测费用: 同条件30/组，标准养护70/组</p>
    </a-modal>
    <a-modal v-model="modal72" title='混凝土配合比认证' :footer="null" centered >
      <p>类别：HJ</p>
      <p>检测参数：<br>混凝土公司提供以下物品材料:<br>水泥30kg<br>砂40kg<br>石子50kg<br>石粉/矿粉/粉煤灰10斤<br>外加剂1.5斤
      <br>附带混凝土公司提供的各种原材料的检测报告与配比表。</p>
      <p>检测费用:600</p>
    </a-modal>
    <a-modal v-model="modal73" title='混凝土配合比' :footer="null" centered >
      <p>类别：HP</p>
      <p>检测参数：水泥30kg<br>砂40kg<br>石子50kg<br>设计用水泥50kg砂80kg石子100kg，煤灰矿粉各20kg，外加剂按掺量且不少于2kg。</p>
      <p>检测周期：45个工作日</p>
      <p>检测费用:600</p>
    </a-modal>
    <a-modal v-model="modal74" title='灰剂量曲线' :footer="null" centered >
      <p>类别：HU</p>
      <p>检测参数：灰剂量曲线(灰土、泥灰碎石)<br>水泥剂量曲线(水泥稳定碎石)</p>
      <p>样品规格: <br>灰士:5斤士，1斤灰<br>泥灰碎石:2斤灰，3斤土，3斤碎石<br>水泥剂量曲线:石子，瓜子片，米砂，水泥各5斤左右
      <br>以上提供原材，同一工程检测一次<br>衰减曲线为5倍工作量</p>
      <p>检测费用:细粒600、粗粒1000</p>
    </a-modal>
    <a-modal v-model="modal75" title='砂(市政用)' :footer="null" centered >
      <p>类别：JC</p>
      <p>检测参数：最大干密度</p>
      <p>样品规格:40斤</p>
      <p>检测费用:1000</p>
    </a-modal>
    <a-modal v-model="modal76" title='管桩，方桩' :footer="null" centered >
      <p>类别：JG</p>
      <p>代表数量：300根</p>
      <p>检测费用:预应力管桩5000，混凝土实心/空心方桩5000小方桩3300</p>
    </a-modal>
    <a-modal v-model="modal77" title='混凝土预制板(叠合板;预制楼梯)' :footer="null" centered >
      <p>类别：JG</p>
      <p>检测参数：承载力</p>
      <p>样品规格: 1块整板</p>
      <p>代表数量: 1000块</p>
      <p>检测费用: 叠合板:6000,预制楼梯:12000,预制剪力墙:9000,预制梁、预制柱:9000</p>
    </a-modal>
    <a-modal v-model="modal78" title='胶粘剂(粘结砂浆)' :footer="null" centered >
      <p>类别：JJ</p>
      <p>检测参数：拉伸粘结强度</p>
      <p>样品规格: 5公斤</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal79" title='界面砂浆' :footer="null" centered >
      <p>类别：JM</p>
      <p>检测参数：原强度<br>耐水强度</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal80" title='涂料甲醛' :footer="null" centered >
      <p>类别：JQ</p>
      <p>检测标准:GB 18582-2020《建筑用墙面涂料中有害物质限量》</p>
      <p>检测参数：游离甲醛含量、苯+甲苯+乙苯+二甲苯</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶,附质保书复印件</p>
      <p>检测费用: 甲醛:800(另:苯、甲苯、乙苯、二甲苯：每项300)<br>组批按同一厂家同一品种统一规格产品每 5t为一批，不足 5t 按一批</p>
    </a-modal>
    <a-modal v-model="modal81" title='涂料 VOC' :footer="null" centered >
      <p>类别：JQ</p>
      <p>检测标准:GB 18582-2020《建筑用墙面涂料中有害物质限量》</p>
      <p>检测参数：VOC</p>
      <p>样品规格: 未拆封商品原装桶,附质保书复印件</p>
      <p>检测费用: 组批按同一厂家同一品种统一规格产品每 5t为一批,不足 5t 按一批计</p>
    </a-modal>
    <a-modal v-model="modal82" title='人造板材(有害物质)' :footer="null" centered >
      <p>类别：JR</p>
      <p>检测参数：游离甲醛释放量</p>
      <p>样品规格: 500mm*500mm=2块<br>条型地板面向外双层井字型拼放螺丝固定为一块贴好品牌标志(留至少1.5边不封样)</p>
      <img src="../assets/img4.jpg">
      <p>检测费用: 7000</p>
    </a-modal>
    <a-modal v-model="modal83" title='击实' :footer="null" centered >
      <p>类别：JS</p>
      <p>检测参数：最佳含水率，最大干密度<br>注意:<br>泥灰碎石、二灰碎石、水泥稳定碎石需提供配台比，并每种石子分别做市政筛分。</p>
      <p>样品规格: 原土/素土:50斤<br>灰土:石灰10斤，土50斤<br>二灰碎石:石灰30斤，粉煤灰30斤，每种碎石50斤，提供配合比<br>泥灰碎石:石灰20斤，土30斤，每种碎石各50斤，提供配合比
        <br>水泥稳定碎石:水泥5斤，每种碎石各80斤</p>
      <p>检测费用: 土:1000;无机结合料-细(灰土等)1500;无机结合料-粗(泥灰碎石，水稳等)2000</p>
    </a-modal>
    <a-modal v-model="modal84" title='水泥' :footer="null" centered >
      <p>类别：SN</p>
      <p>检测参数：胶砂强度<br>胶砂流动度<br>细度<br>凝结时间<br>安定性<br>标准用水量</p>
      <p>送样数量:12kg，需提供质保书,封样两份，都装入我单位留样桶内</p>
      <p>代表数量:袋装水泥200t,散装水泥500t</p>
      <p>检测费用: 720</p>
    </a-modal>
    <a-modal v-model="modal85" title='矿粉(市政)' :footer="null" centered >
      <p>类别：KF</p>
      <p>检测参数：筛分<br>表观密度<br>亲水系数<br>含水率</p>
      <p>样品规格:5kg</p>
      <p>代表数量:50t</p>
      <p>检测费用: 490</p>
    </a-modal>
    <a-modal v-model="modal86" title='开关' :footer="null" centered >
      <p>类别：KG</p>
      <p>检测参数：通断能力、正常操作、防潮、机械强度、绝缘电阻和、电气强度、耐燃</p>
      <p>样品规格: 6个(写明型号、规格)</p>
      <p>检测费用: 950</p>
    </a-modal>
    <a-modal v-model="modal87" title='混凝土试块抗渗' :footer="null" centered >
      <p>类别：KS</p>
      <p>检测参数：抗渗等级</p>
      <p>样品规格: 6块/组</p>
      <p>代表数量: 由监理确认</p>
      <p>检测周期: 到检测龄期后5个工作日</p>
      <p>检测费用: 标养，P6级580，P7级630，P8级680(每级50)</p>
    </a-modal>
    <a-modal v-model="modal88" title='混凝土试块抗折' :footer="null" centered >
      <p>类别：KZ</p>
      <p>检测参数：抗折强度</p>
      <p>样品规格: 3块/组</p>
      <p>代表数量: 同混凝土抗压</p>
      <p>检测周期: 到检测龄期后5个工作日</p>
      <p>检测费用: 同条件50/组，标准养护90/组</p>
    </a-modal>
    <a-modal v-model="modal89" title='现场-保温锚固件' :footer="null" centered >
      <p>类别：LB</p>
      <p>检测参数：锚栓现场测试抗拉承载力标准值</p>
      <p>代表数量: 1000㎡</p>
      <p>检测费用: 7500/组</p>
    </a-modal>
    <a-modal v-model="modal90" title='构造深度' :footer="null" centered >
      <p>类别：LG</p>
      <p>检测参数：构造深度</p>
      <p>检测费用: 18/点，单组结算价不低于1000元</p>
    </a-modal>
    <a-modal v-model="modal91" title='窗三性' :footer="null" centered >
      <p>类别：LH</p>
      <p>检测参数：气密性<br>水密性<br>抗风压性能</p>
      <p>样品规格: 3樘(1200*1200左右)<br>委托时写明:品种、开启方式，玻璃厚度、玻璃和框扇的密封材料三性要求。</p>
      <p>代表数量: 5000㎡</p>
      <p>检测费用: 2400</p>
    </a-modal>
    <a-modal v-model="modal92" title='抗裂砂浆，抹面砂浆' :footer="null" centered >
      <p>类别：LJ</p>
      <p>检测参数：常温常态拉伸粘结强度<br>耐水拉伸粘结强度<br>压折比</p>
      <p>样品规格: 5公斤/份<br>若单独检测附质保书复印件<br>单独送检砂浆，附带配套板材70.7m㎡十二块</p>
      <p>检测费用: 1300</p>
    </a-modal>
    <a-modal v-model="modal93" title='沥青含量及矿料级配' :footer="null" centered >
      <p>类别：LK</p>
      <p>检测参数：沥青含量<br>矿料级配(沥青含量和矿料级配+马歇尔稳定度)</p>
      <p>检测费用: 1300</p>
    </a-modal>
    <a-modal v-model="modal94" title='马歇尔稳定度' :footer="null" centered >
      <p>类别：LM</p>
      <p>检测参数：马歇尔稳定度</p>
      <p>样品规格：10kg</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal95" title='沥青' :footer="null" centered >
      <p>类别：LQ</p>
      <p>检测参数：<br>沥青:软化点、针入度、延度<br>改性沥青:软化点、针入度、延度<br>乳化沥青:针入度、延度、溶解度、蒸发残留物
      <br>改性乳化沥青:软化度、针入度、延度、溶解度、蒸发残留物</p>
      <p>样品规格：2kg</p>
      <p>检测费用: 沥青:760;改性沥青:1570;乳化沥青:1200;改性乳化沥青:2290</p>
    </a-modal>
    <a-modal v-model="modal96" title='钻芯(市政道路)' :footer="null" centered >
      <p>类别：LR</p>
      <p>检测参数：厚度<br>压实度(沥青路面)</p>
      <p>检测费用: 水稳基层厚度:钻芯一层500/点 水稳基层厚度:钻芯二层700/点 沥青混凝土面层:钻芯一层400/点沥青混凝士面层:钻芯二层500/点 沥青混凝士面层:钻芯三层600/点 水泥混凝土路面厚度:530/点</p>
    </a-modal>
    <a-modal v-model="modal97" title='混凝土拌和物中水溶性氯离子含量' :footer="null" centered >
      <p>类别：LX</p>
      <p>检测参数：水溶性氯离子含量</p>
      <p>样品规格：5斤去石子未凝固拌和物(浆料)<br>上午9:30之前，下午3:00之前送到<br>带厂家配比单原件，提前半天预约<br>不取号直接插队送检(仅限此项氯离子检测)</p>
      <p>检测费用: 3500(硬化后混凝土中氯离子含量、既有结构中氯离子含量另加取芯费用600/组)</p>
    </a-modal>
    <a-modal v-model="modal98" title='混凝土路缘石(平石，侧石)' :footer="null" centered >
      <p>类别：LY</p>
      <p>检测参数：规则长方体立缘石平缘石抗折(原整块),其余形状抗压(加工成100m³)</p>
      <p>样品规格：3块</p>
      <p>代表数量: 20000块</p>
      <p>检测费用: 抗折180、抗压180</p>
    </a-modal>
    <a-modal v-model="modal99" title='混凝土路面砖' :footer="null" centered >
      <p>类别：LZ</p>
      <p>检测参数：长度/厚度≤4抗压<br>长度/厚度>4抗折</p>
      <p>样品规格：10块</p>
      <p>代表数量: 3000㎡(同一类别、规格、强度等级)</p>
      <p>检测费用: 抗折180、抗压180</p>
    </a-modal>
    <a-modal v-model="modal100" title='植草砖' :footer="null" centered >
      <p>类别：LZ</p>
      <p>检测参数：抗折、抗压各5块</p>
      <p>代表数量: 5000</p>
      <p>检测费用: 180</p>
    </a-modal>
    <a-modal v-model="modal101" title='透水砖' :footer="null" centered >
      <p>类别：LZ</p>
      <p>检测参数：抗折，抗压(边长/厚度>5要做抗折)..暂停授理</p>
      <p>样品规格: 抗折、抗压各5块</p>
      <p>检测费用: 180</p>
    </a-modal>
    <a-modal v-model="modal102" title='窗传热' :footer="null" centered >
      <p>类别：MD</p>
      <p>检测参数：传热系数</p>
      <p>样品规格: 去附框窗1樘<br>尺寸不大于1100*1100<br>委托时写明:品种、开启方式、玻璃厚度、玻璃和框扇的密封材料传热系数设计值(带相关蓝图，节能设计书复印件)。</p>
      <p>检测费用: 9000</p>
    </a-modal>
    <a-modal v-model="modal103" title='现场-饰面砖、石材，碳纤维' :footer="null" centered >
      <p>类别：ML</p>
      <p>检测参数：抗拔力</p>
      <p>代表数量: 合同确定</p>
      <p>检测费用: 500/块。按实际点数结算</p>
    </a-modal>
    <a-modal v-model="modal104" title='现场-螺栓、植筋' :footer="null" centered >
      <p>类别：MS</p>
      <p>检测参数：抗拔力</p>
      <p>样品规格：3根/组、5根/组</p>
      <p>代表数量: 合同确定</p>
      <p>检测费用: 500/块。按实际点数结算</p>
    </a-modal>
    <a-modal v-model="modal105" title='抹灰砂浆粘结强度' :footer="null" centered >
      <p>类别：MZ</p>
      <p>检测参数：抹灰砂浆粘结强度</p>
      <p>检测费用: 3500</p>
    </a-modal>
    <a-modal v-model="modal106" title='硅酸钙板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：A1燃烧等级</p>
      <p>样品规格: 1㎡一块<br>A1燃烧等级:1㎡一1块(原厚度)<br>A2燃烧等级:1㎡二8块(原厚度)<br>(GB8624-2012)</p>
      <p>检测费用: 2800</p>
    </a-modal>
    <a-modal v-model="modal107" title='电子交联发泡聚乙烯减震垫层' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数<br>密度<br>压缩强度</p>
      <p>样品规格: 2㎡<br>附企标册</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal108" title='岩棉带' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>抗拉强度<br>短期吸水量<br>导热系数</p>
      <p>样品规格: 600-1000长6条<br>另加工300*300*(40厚以下)2块<br>附质保书或形式检验报告复印件(须盖项目章或监理章)，提供密度值</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 2400</p>
    </a-modal>
    <a-modal v-model="modal109" title='ZL矿物纤维喷涂层' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数<br>密度</p>
      <p>样品规格: 300*300*30=2块<br>附企标册</p>
      <p>检测费用: 900</p>
    </a-modal>
    <a-modal v-model="modal110" title='DN保温隔声垫(软卷)' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数<br>密度<br>压缩强度</p>
      <p>样品规格: 2㎡</p>
      <p>检测费用: 1400(吸水率另加400)</p>
    </a-modal>
    <a-modal v-model="modal111" title='聚氨酯复合保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>压缩强度<br>导热系数<br>吸水率</p>
      <p>样品规格: 1200mm*600mm=2块<br>燃烧性能16块<br>附质保书复印件</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 常规1800,B1燃烧5800、氧指数300</p>
    </a-modal>
    <a-modal v-model="modal112" title='蒸压加气混凝土砌块，蒸压加气混凝土板(导热)' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数(DGJ32/TJ107-2010)</p>
      <p>样品规格: 300*300=2块(原厚度,>40mm加工成30mm)<br>附质保书复印件</p>
      <p>检测周期: 35个工作日</p>
      <p>检测费用: 600</p>
    </a-modal>
    <a-modal v-model="modal113" title='HKS保温隔声板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>导热系数<br>压缩强度</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal114" title='ALG纳米橡塑保温隔声垫(板)' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>导热系数<br>压缩强度</p>
      <p>样品规格：2㎡</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal115" title='橡塑保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>压缩强度<br>导热系数</p>
      <p>样品规格: 2㎡</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal116" title='复合材料保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：干密度<br>抗压强度<br>导热系数<br>抗拉强度<br>吸水率</p>
      <p>样品规格: 300*300*30mm=2块(原厚度,>40mm加工成30mm厚)<br>150*150mm=3块(原厚度)<br>100*100mm=10块(原厚度)</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 2300</p>
    </a-modal>
    <a-modal v-model="modal117" title='保温腻子' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数</p>
      <p>样品规格: 300*300*30mm=2块<br>提供标准，附质保书复印件</p>
      <p>检测周期: 35个工作日</p>
      <p>检测费用: 600</p>
    </a-modal>
    <a-modal v-model="modal118" title='泡沫混凝土(保温)' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数</p>
      <p>样品规格: 300*300*30mm=2块<br>提供标准，附质保书复印件</p>
      <p>检测周期: 35个工作日</p>
      <p>检测费用: 600</p>
    </a-modal>
    <a-modal v-model="modal119" title='EHS纳米橡塑隔声保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>导热系数<br>压缩强度</p>
      <p>样品规格: 2㎡</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal120" title='FS-A保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>导热系数<br>抗拉强度<br>抗压强度<br>体积吸水率</p>
      <p>样品规格: 1200*600=2块</p>
      <p>检测费用: 2300</p>
    </a-modal>
    <a-modal v-model="modal121" title='EPS模塑聚苯板/黑泡高性能保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>尺寸稳定性<br>导热系数<br>吸水率<br>垂直于板面方向的抗拉强度/抗拉强度(墙面)</p>
      <p>样品规格: 1200mm*600mm<br>常规检测2块<br>燃烧性能16块</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 屋面、地坪:1600;墙面:2100,另加B级燃烧5800，氧指数300</p>
    </a-modal>
    <a-modal v-model="modal122" title='泡沫玻璃' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：密度<br>导热系数<br>抗压强度<br>抗拉强度<br>吸水率</p>
      <p>样品规格: 300*300*30=2块<br>150*150*原厚=6块<br>100*100*原厚=10块<br>附质保书复印件</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 2300</p>
    </a-modal>
    <a-modal v-model="modal123" title='保温装饰板(硅酸钙板+岩棉)' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：单位面积质量<br>抗拉强度<br>导热系数</p>
      <p>样品规格: 100*100mm带皮=5块<br>300*300mm芯样=2块</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal124" title='自保温砖' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数</p>
      <p>样品规格: 300*300*30mm=2块<br>附质保书复印件</p>
      <p>检测周期: 35个工作日</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal125" title='BGL地面隔声保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>导热系数<br>压缩强度</p>
      <p>样品规格: 1200*600=2块</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal126" title='阻燃板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：B1燃烧</p>
      <p>样品规格: 1500*1000=4块<br>1500*500=4块<br>250*90=8块</p>
      <p>检测费用: B1燃烧5800、氧指数300</p>
    </a-modal>
    <a-modal v-model="modal127" title='XPS挤塑聚苯板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：尺寸稳定性<br>垂直于板面方向的抗拉强度/抗拉强度(仅墙面板)<br>表观密度(仅墙面板)<br>导热系数（厚度不大于50mm，300*300.2块）<br>压缩强度<br>吸水率</p>
      <p>样品规格: 1200mm*600mm<br>常规检测2块<br>燃烧性能16块</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 1800(屋面.地坪)/2600(墙面)，B级燃烧5800，氧指数300（常规用于墙面才需检测）</p>
    </a-modal>
    <a-modal v-model="modal128" title='发泡陶瓷保温板(泡沫玻璃)' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：干密度<br>导热系数<br>抗拉强度<br>抗压强度<br>燃烧性能<br>吸水率</p>
      <p>样品规格: 300*300=2块(原厚度,>40mm时加工成30mm厚)<br>150*150mm=3块(原厚度)<br>100*100mm=10块(原厚度)<br>有保温砂浆附70mm*70mm=12块(原厚度)
        <br>送检附带企标册，提供具体型号<br>燃烧性能300*300=6块(原厚度)</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 常规2300，A1燃烧2800(燃烧代表数量详询质监站)</p>
    </a-modal>
    <a-modal v-model="modal129" title='岩棉板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：密度<br>导热系数<br>短期吸水量(部分浸入)<br>垂直于表面的抗拉强度</p>
      <p>样品规格: 1200mm*600mm=2块<br>150*150mm=3块(原厚度)<br>提供密度值<br>附质保书或形式检验报告复印件(须盖项目章或监理章)<br>燃烧性能1块</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 2400</p>
    </a-modal>
    <a-modal v-model="modal130" title='石膏基无机保温砂浆' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数<br>抗压强度<br>干密度</p>
      <p>样品规格: 300*300*30mm=2块<br>70.7*70.7*70.7mm=6块<br>附型式检验报告<br>成型28天后可送检</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 1600</p>
    </a-modal>
    <a-modal v-model="modal131" title='淤泥烧结保温砖' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：导热系数</p>
      <p>样品规格: 300*300*30mm=2块</p>
      <p>检测周期: 35个工作日</p>
      <p>检测费用: 2600</p>
    </a-modal>
    <a-modal v-model="modal132" title='复合岩棉防火保温板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：单位面积质量<br>导热系数<br>抗拉强度</p>
      <p>样品规格: 100*100mm带皮=5块<br>300*300mm芯样=2块</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 1400</p>
    </a-modal>
    <a-modal v-model="modal133" title='DN保温隔声板' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：表观密度<br>导热系数<br>压缩强度<br>吸水率</p>
      <p>样品规格: 1200mm*600mm<br>常规检测2块<br>燃烧性能16块</p>
      <p>代表数量: ≤1000㎡-组<br>≤3000㎡二组<br>5000㎡三组<br>5001-10000㎡四组<br>10000㎡以上每5000㎡增加-组</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 1800/组 B级燃烧5800，氧指数300</p>
    </a-modal>
    <a-modal v-model="modal134" title='抹灰石膏' :footer="null" centered >
      <p>类别：PB</p>
      <p>检测参数：抗压强度<br>抗折强度<br>体积密度<br>导热系数</p>
      <p>样品规格: 300*300*30mm=2块<br>70.7mm³=6块<br>附型式检验报告<br>成型28天后可送检</p>
      <p>检测费用: 1100</p>
    </a-modal>
    <a-modal v-model="modal135" title='现场-粉刷砂浆强度' :footer="null" centered >
      <p>类别：PL</p>
      <p>检测参数：拉伸粘结强度</p>
      <p>代表数量: 外墙5000㎡</p>
      <p>检测费用: 2500</p>
    </a-modal>
    <a-modal v-model="modal136" title='现场-砌筑砂浆强度' :footer="null" centered >
      <p>类别：QJ</p>
      <p>检测参数：砂浆强度(贯入)</p>
      <p>代表数量: 合同确定</p>
      <p>检测费用: 600/测区,按实际点数结算</p>
    </a-modal>
    <a-modal v-model="modal137" title='蒸压加气混凝土砌块，蒸压加气混凝土板(原材)' :footer="null" centered >
      <p>类别：QK</p>
      <p>检测参数：抗压强度<br>干密度<br>导热系数</p>
      <p>样品规格: 10cm³=18块<br>若有导热系数加2块</p>
      <p>代表数量: <br>砌块:30000块<br>屋面板，楼板:3000块<br>外墙板:5000块<br>隔墙板:10000块</p>
      <p>检测费用: 380</p>
    </a-modal>
    <a-modal v-model="modal138" title='普通混凝土小型空心砌块' :footer="null" centered >
      <p>类别：QL</p>
      <p>检测参数：抗压强度</p>
      <p>样品规格: 10块</p>
      <p>代表数量: 10000</p>
      <p>检测费用: 抗压强度190</p>
    </a-modal>
    <a-modal v-model="modal139" title='混凝土空心砌块' :footer="null" centered >
      <p>类别：QL</p>
      <p>检测参数：抗压强度<br>密度等级</p>
      <p>样品规格: 6块</p>
      <p>检测费用: 抗压强度190</p>
    </a-modal>
    <a-modal v-model="modal140" title='现场-钻芯法测强度(结构取芯)' :footer="null" centered >
      <p>类别：QX</p>
      <p>检测参数：钻芯法测强度</p>
      <p>代表数量: 合同确定</p>
      <p>检测费用: 600/个,按实际点数结算</p>
    </a-modal>
    <a-modal v-model="modal141" title='砂(土建用)' :footer="null" centered >
      <p>类别：SA</p>
      <p>检测参数：筛分析<br>含泥量(仅天然砂)<br>泥块含量<br>表观密度(堆积密度)<br>氯离子含量<br>石粉含量(仅人工砂)</p>
      <p>样品规格: 45斤</p>
      <p>代表数量: 600t</p>
      <p>检测费用: 天然砂660、人工砂1180</p>
    </a-modal>
    <a-modal v-model="modal142" title='大理石、花岗岩' :footer="null" centered >
      <p>类别：SC</p>
      <p>检测参数：压缩强度<br>弯曲强度<br>吸水率</p>
      <p>样品规格: 抗压:50*50*50mm=9块,吸水率5块<br>抗折:(板厚*10+50mm)长*100mm宽=5块<br>请在委托单上写明石材具体品种</p>
      <p>检测费用: 300</p>
    </a-modal>
    <a-modal v-model="modal143" title='石灰' :footer="null" centered >
      <p>类别：SD</p>
      <p>检测参数：钙镁含量<br>未消化残渣含量</p>
      <p>样品规格: 5kg，若有灰剂量为10kg</p>
      <p>代表数量: 200吨</p>
      <p>检测费用: 345</p>
    </a-modal>
    <a-modal v-model="modal144" title='砂浆试块抗压' :footer="null" centered >
      <p>类别：SJ</p>
      <p>检测参数：抗压强度</p>
      <p>样品规格: 70.7*70.7*70.7=3块/组</p>
      <p>代表数量: 由监理确认</p>
      <p>检测周期: 到检测龄期后5个工作日</p>
      <p>检测费用: 自养30/组，标准养60/组</p>
    </a-modal>
    <a-modal v-model="modal145" title='水泥砂浆、混合砂浆配合比' :footer="null" centered >
      <p>类别：SP</p>
      <p>检测参数：配合比<br>稠度<br>抗压<br>保水率<br>密度</p>
      <p>样品规格:30kg水泥50kg砂</p>
      <p>检测周期: 45个工作日(理论配合比10个工作日)</p>
      <p>检测费用: 760</p>
    </a-modal>
    <a-modal v-model="modal146" title='粉煤灰(道路用)' :footer="null" centered >
      <p>类别：SS</p>
      <p>检测参数：烧失量<br>三氧化硫</p>
      <p>样品规格: 10kg</p>
      <p>代表数量: 200t</p>
      <p>检测周期: 10个工作日</p>
      <p>检测费用: 430</p>
    </a-modal>
    <a-modal v-model="modal147" title='粉煤灰(混凝土用)' :footer="null" centered >
      <p>类别：SS</p>
      <p>检测参数：细度<br>需水量比</p>
      <p>样品规格: 5kg</p>
      <p>检测费用: 175</p>
    </a-modal>
    <a-modal v-model="modal148" title='水泥土(试块)' :footer="null" centered >
      <p>类别：ST</p>
      <p>检测参数：抗压强度<br>样品规格加若需做配合比士样应是在施工现场现取，用厚聚氯乙烯塑料袋封装</p>
      <p>样品规格: 6块/组<br>一个配合比25kg+10kg水泥,(按配比、剂量不同调整，不包括原材料检 测)，提供图纸</p>
      <p>检测费用: 标准养护60，自养护30</p>
    </a-modal>
    <a-modal v-model="modal149" title='碎石，卵石' :footer="null" centered >
      <p>类别：SZ</p>
      <p>检测参数：筛分析<br>含泥量<br>泥块含量<br>针片状颗粒含量<br>压碎值<br>表观密度(堆积密度)</p>
      <p>样品规格: 30kg</p>
      <p>代表数量: 600吨</p>
      <p>检测周期: 7个工作日</p>
      <p>检测费用: 460</p>
    </a-modal>
    <a-modal v-model="modal150" title='瓷砖' :footer="null" centered >
      <p>类别：TC</p>
      <p>检测参数：吸水率<br>破坏强度<br>断裂模数</p>
      <p>样品规格: 8整块，主至少有一组带原包装</p>
      <p>检测周期: 10000㎡个工作日</p>
      <p>检测费用: 280</p>
    </a-modal>
    <a-modal v-model="modal151" title='压实度' :footer="null" centered >
      <p>类别：TG</p>
      <p>检测参数：压实度</p>
      <p>检测费用: 改灌砂300/点、环刀120/点</p>
    </a-modal>
    <a-modal v-model="modal152" title='土工布、塑料土工格栅、玻璃纤维土工格栅' :footer="null" centered >
      <p>类别：TH</p>
      <p>检测参数：单位面积质量<br>断裂强力<br>断裂伸长率<br>幅宽<br>厚度</p>
      <p>样品规格: 2㎡,送检附带一份出厂报告和两份质保书复印件<br>
        土工布50卷为一批，不足50卷也为一批，随机抽取一卷，距头端至少3m剪取样品</p>
      <p>检测费用: 土工布610.土工格栅530</p>
    </a-modal>
    <a-modal v-model="modal153" title='合成树脂乳液内墙涂料' :footer="null" centered >
      <p>类别：TL</p>
      <p>检测参数：容器中状态<br>施工性<br>干燥时间(表干)<br>对比率<br>涂膜外观<br>耐洗刷性<br>耐碱性</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶,附质保书复印件</p>
      <p>检测费用: 内墙涂料(面漆):1160;内墙涂料(底漆):910</p>
    </a-modal>
    <a-modal v-model="modal154" title='建筑内外墙用底漆' :footer="null" centered >
      <p>类别：TL</p>
      <p>检测参数：容器中状态<br>施工性<br>干燥时间(表干)<br>对比率<br>涂膜外观<br>耐洗刷性<br>耐碱性</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶,附质保书复印件</p>
      <p>检测费用: 建筑内外墙用底漆(内墙 成膜型):910;建筑内外墙用底漆(内墙 渗透型):890:建筑内外墙用底漆(外墙工型成膜型 Ⅱ型成膜型):1110;建筑内外墙用底漆(外墙 I型渗透型 Ⅱ型渗透型):1090</p>
    </a-modal>
    <a-modal v-model="modal155" title='建筑外墙用腻子' :footer="null" centered >
      <p>类别：TL</p>
      <p>检测参数：容器中状态<br>施工性<br>干燥时间(表干)<br>对比率<br>涂膜外观<br>耐洗刷性<br>耐碱性</p>
      <p>样品规格: 未拆封商品原装，25斤或以上</p>
      <p>检测费用: 1070</p>
    </a-modal>
    <a-modal v-model="modal156" title='合成树脂乳液外墙涂料' :footer="null" centered >
      <p>类别：TL</p>
      <p>检测参数：容器中状态<br>施工性<br>干燥时间(表干)<br>对比率<br>涂膜外观<br>耐洗刷性<br>耐碱性</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶,附质保书复印件</p>
      <p>检测费用: 外墙涂料(面漆):1360;外墙涂料(中涂漆):1060:外墙涂料(底漆):1110</p>
    </a-modal>
    <a-modal v-model="modal157" title='弹性建筑涂料' :footer="null" centered >
      <p>类别：TL</p>
      <p>检测参数：拉伸性能<br>容器中状态<br>施工性<br>干燥时间(表干)<br>对比率<br>涂膜外观<br>耐洗刷性<br>耐碱性</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶,附质保书复印件</p>
      <p>检测费用: 弹性建筑涂料(外墙面涂):1390;弹性建筑涂料(外墙中涂):1090</p>
    </a-modal>
    <a-modal v-model="modal158" title='建筑室内用腻子' :footer="null" centered >
      <p>类别：TL</p>
      <p>检测参数：容器中状态<br>施工性<br>干燥时间<br>耐水性<br>粘强强度标准状态<br>粘强强度浸水后</p>
      <p>样品规格: 未拆封商品原装，25斤或以上</p>
      <p>代表数量: 15吨</p>
      <p>检测费用: 建筑室内用腻子(一般型Y):660:建筑室内用腻子(耐水型N):1170:建筑室内用腻子(柔韧型R):860</p>
    </a-modal>
    <a-modal v-model="modal159" title='无机涂料' :footer="null" centered >
      <p>类别：TL</p>
      <p>样品规格: 5公斤或以上未拆封商品原装桶,附质保书复印件</p>
    </a-modal>
    <a-modal v-model="modal160" title='弹性模量' :footer="null" centered >
      <p>类别：TM</p>
      <p>样品规格: 6块/组</p>
      <p>代表数量: 由监理确认</p>
      <p>检测费用: 240</p>
    </a-modal>
    <a-modal v-model="modal161" title='现场-闭水' :footer="null" centered >
      <p>类别：TQ</p>
      <p>检测费用: 500元/段，且单组结算价不低于3000元。检测段数请电询现场结构节能科(52873516)</p>
    </a-modal>
    <a-modal v-model="modal162" title='土壤(有害物质)' :footer="null" centered >
      <p>类别：TR</p>
      <p>检测参数：土壤氢浓度</p>
      <p>样品规格: 100㎡</p>>
      <p>检测费用: 150/点</p>
    </a-modal>
    <a-modal v-model="modal163" title='现场-钢结构检测(探伤)' :footer="null" centered >
      <p>类别：TS</p>
      <p>检测参数：焊缝无损检测</p>
      <p>代表数量: 合同确定</p>
      <p>检测费用: 100/米,管桩每条缝500，单组结算价不低于1000元</p>
    </a-modal>
    <a-modal v-model="modal164" title='灌浆料(原材，强度50及以上)' :footer="null" centered >
      <p>类别：VG</p>
      <p>检测参数：流动度<br>竖向膨胀率<br>抗压强度</p>
      <p>样品规格: 至少30KG</p>
      <p>检测费用: 1500</p>
    </a-modal>
    <a-modal v-model="modal165" title='灌浆料(试块，强度为50及以上)' :footer="null" centered >
      <p>类别：VG</p>
      <p>检测参数：抗压强度</p>
      <p>样品规格: <br>钢筋套筒及水泥基Ⅰ，Ⅱ，Ⅲ类灌浆料:40*40*160mm<br>水泥基IV类灌浆料:150mm³<br>标养，以上试件3块为一组</p>
      <p>检测费用: 600</p>
    </a-modal>
    <a-modal v-model="modal166" title='混凝土瓦' :footer="null" centered >
      <p>类别：WA</p>
      <p>检测参数：承载力<br>吸水率<br>抗渗性能</p>
      <p>样品规格: 15块</p>
      <p>检测费用: 365</p>
    </a-modal>
    <a-modal v-model="modal167" title='烧结瓦' :footer="null" centered >
      <p>类别：WB</p>
      <p>检测参数：抗弯曲性能<br>吸水率<br>抗渗性能(无釉的烧结瓦需加做此项)</p>
      <p>样品规格: 15块</p>
      <p>代表数量: 35000</p>
      <p>检测费用: 有釉烧结瓦:185;无轴烧结瓦:365</p>
    </a-modal>
    <a-modal v-model="modal168" title='现场-道路弯沉' :footer="null" centered >
      <p>类别：WC</p>
      <p>检测费用: 15元/点，每组另加标准加载车费用:800元(测点80以内):1000元/天(测点大于80)</p>
    </a-modal>
    <a-modal v-model="modal169" title='混凝土外加剂' :footer="null" centered >
      <p>类别：WJ</p>
      <p>检测参数：减水率<br>凝结时间差<br>泌水率比<br>抗压强度比<br>含气量</p>
      <p>样品规格: 10kg</p>
      <p>代表数量: 50t</p>
      <p>检测周期: 10个工作日</p>
      <p>检测费用: 130</p>
    </a-modal>
    <a-modal v-model="modal170" title='高分子乳液改性沥青防水涂料' :footer="null" centered >
      <p>类别：WW</p>
      <p>检测周期: 委外检测</p>
    </a-modal>
    <a-modal v-model="modal171" title='预应力孔道压浆料' :footer="null" centered >
      <p>类别：WW</p>
      <p>检测周期: 委外检测</p>
      <p>检测费用: 2000</p>
    </a-modal>
    <a-modal v-model="modal172" title='水性多彩涂料' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>样品规格: HG/T4343标准，原包装5kg以上</p>
    </a-modal>
    <a-modal v-model="modal173" title='胶粘剂(有害物质)' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：室内装饰装修材料 胶粘剂中有害物质限量</p>
    </a-modal>
    <a-modal v-model="modal174" title='真石漆' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：容器中状态<br>施工性<br>干燥时间(表干)<br>耐水性<br>耐碱性<br>VOC含量</p>
      <p>样品规格: 5kg</p>
      <p>检测费用: 5100</p>
    </a-modal>
    <a-modal v-model="modal175" title='钢筋砼管' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>样品规格: 2根</p>
      <p>检测费用: 1800</p>
    </a-modal>
    <a-modal v-model="modal176" title='建筑用硅酮结构胶' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：邵氏硬度<br>标准条件拉伸粘结性<br>相容性</p>
      <p>样品规格: <br>单组份胶2支或双组份胶按配合比合计2KG<br>带相容试件(如:玻璃,铝板等)2件(300*300mm)<br>
        铝副框4支(300mm)<br>双面胶条(1m)<br>泡沫棒(1m)</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 3400</p>
    </a-modal>
    <a-modal v-model="modal177" title='止水带,止水条' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：硬度<br>拉伸强度<br>扯断伸长率<br>撕裂强度</p>
      <p>样品规格: 不少于1米</p>
      <p>检测周期: 15个工作日</p>
      <p>检测费用: 钢边止水带:11150，非钢边止水带:10750</p>
    </a-modal>
    <a-modal v-model="modal178" title='建筑用硅酮密封胶' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：相容性</p>
      <p>样品规格: <br>单组份胶2支或双组份胶按配合比合计2KG<br>带相容试件(如:玻璃,铝板等)2件(300*300mm)<br>
        铝副框4支(300mm)<br>双面胶条(1m)<br>泡沫棒(1m)<br>非结构装配用密封胶(GB/T14683-2017)<br>两支胶，无需配件</p>
      <p>检测周期: 30个工作日</p>
      <p>检测费用: 3400</p>
    </a-modal>
    <a-modal v-model="modal179" title='输送流体用无缝钢管' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：外观<br>外径<br>壁厚<br>拉伸试验<br>伸长率</p>
      <p>样品规格: 2根1m</p>
      <p>检测周期: 15个工作日</p>
      <p>检测费用: 1000(力学500化学500)</p>
    </a-modal>
    <a-modal v-model="modal180" title='混凝土排水管(有筋)' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>样品规格: 2根(长度≤2.2m，外径不超过1.5m)</p>
      <p>检测周期: 10个工作日</p>
      <p>检测费用: 1800</p>
    </a-modal>
    <a-modal v-model="modal181" title='合成树脂乳液砂壁状建筑涂料(JGT24)' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>样品规格: 5公斤以上</p>
    </a-modal>
    <a-modal v-model="modal182" title='环氧沥青防腐涂料' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：容器中状态<br>不挥发物含量<br>适用期<br>耐水性<br>施工性<br>干燥时间<br>漆膜外观<br>弯曲试验<br>耐冲击性</p>
      <p>样品规格: 2kq漆+2kg固化剂</p>
      <p>检测周期: 15个工作日</p>
      <p>检测费用: 面涂3000，底涂3000，VOC1000</p>
    </a-modal>
    <a-modal v-model="modal183" title='钢结构防火/防腐涂料 原材' :footer="null" centered >
      <p>类别：GT</p>
      <p>检测参数：<br>容器中状态，粘结强度（膨胀型≥0.15，非膨胀型≥0.04），抗压强度（室外防火涂料≥0/5，室内防火涂料≥0.3）</p>
      <p>样品规格: <br>薄型/膨胀型>5KG(膨胀型附配套的防锈漆250ml(约半矿泉水瓶))<br>厚型/非膨胀型≥8KG</p>
      <p>检测周期: 厚型/非膨胀型30个工作日，薄型/膨胀型10个工作日</p>
      <p>检测费用: 薄型1700:膨胀型1600:厚型2500:非膨胀型2400</p>
    </a-modal>
    <a-modal v-model="modal184" title='地暖管(冷热水用耐热聚乙烯PE-RT管道系统)' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：外观<br>尺寸<br>纵向回缩率<br>液压试验(参照GB/T28799.2-2012)</p>
    </a-modal>
    <a-modal v-model="modal185" title='不锈钢管' :footer="null" centered >
      <p>类别：WWJ</p>
      <p>检测参数：力学性能<br>化学成分</p>
      <p>样品规格: <br>直径≤30=2根300mm<br>直径>30=1根300mm</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal186" title='溶剂型涂料VOC' :footer="null" centered >
      <p>类别：JQ</p>
      <p>检测标准:GB 18582-2020《建筑用墙面涂料中有害物质限量》</p>
      <p>检测参数:vOC、苯+甲苯+乙苯+二甲苯</p>
      <p>样品规格:>5公斤或以上未拆封商品原装桶，多组分涂料需提供各组分配合比</p>
      <p>检测周期: 12个工作日</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal187" title='钢网架螺栓球接点用高强度螺栓' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数: 屈服强度<br>抗拉强度<br>伸长率</p>
      <p>样品规格: 3根螺栓,必加工<br>详情请电询苏州</p>
    </a-modal>
    <a-modal v-model="modal188" title='复层建筑涂料' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数: 参考标准:GB/T9779-2015，附合格证质保书</p>
      <p>样品规格: 1.5kg</p>
    </a-modal>
    <a-modal v-model="modal189" title='橡塑保温管' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数: 规格尺寸与允许偏差<br>外观质量<br>表观密度<br>真空体积吸水率<br>尺寸变化率<br>压缩回弹率<br>
        导热系数(300mm及以上可做。平均温度[25;0;-20]±2°C)<br>抗老化性能<br>燃烧性能(B1)</p>
      <p>样品规格: 常规3根2米(空调管，水管用保温材料)<br>燃烧70米</p>
    </a-modal>
    <a-modal v-model="modal190" title='粘钢胶' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>样品规格: 请电询苏州检测站</p>
      <p>检测周期: 委外检测</p>
    </a-modal>
    <a-modal v-model="modal191" title='反射隔热涂料' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测周期: 委外检测(南京)</p>
      <p>检测费用: 4500</p>
    </a-modal>
    <a-modal v-model="modal192" title='玻璃棉(原材)' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>样品规格: 详询苏州</p>
    </a-modal>
    <a-modal v-model="modal193" title='非固化橡胶沥青防水涂料' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数: 固体含量<br>低温柔性</p>
      <p>样品规格: 每组5kg以上，单独包装</p>
      <p>检测费用: 910</p>
    </a-modal>
    <a-modal v-model="modal194" title='预应力钢绞线' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数: 规定非比例延伸强度<br>最大力下总伸长率<br>应力松弛24h/100h<br>弹性模量</p>
      <p>样品规格: 1m=3根</p>
      <p>检测费用: 3100(应力松弛按100h收费)，应力松弛24h=1000:100h=2500</p>
    </a-modal>
    <a-modal v-model="modal195" title='矿物质电缆' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数: 标准参考:GB/T 34926-2017，附合格证质保书</p>
      <p>样品规格: 14米</p>
    </a-modal>
    <a-modal v-model="modal196" title='纤维水泥板' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数：原材<br>燃烧</p>
      <p>样品规格: 电讯苏州</p>
    </a-modal>
    <a-modal v-model="modal197" title='玻璃棉(燃烧)' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数：A1燃烧等级</p>
      <p>样品规格: 1㎡</p>
      <p>检测费用: 3000</p>
    </a-modal>
    <a-modal v-model="modal198" title='粗集料(沥青用)' :footer="null" centered >
      <p>类别：CS</p>
      <p>检测参数:筛分<br>含泥量(＜0.075颗粒含量)<br>压碎值<br>针片状<br>表观相对密度</p>
      <p>样品规格:50kg/份,要做压碎值的材料数量加倍</p>
      <p>代表数量:600t或400m³按进场批次确定</p>
      <p>检测费用: 筛分220，含泥量140，压碎值220，针片状(水泥)140，针片状(沥青路面)260，表观相对密度140</p>
    </a-modal>
    <a-modal v-model="modal199" title='板式橡胶支座' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数：极限抗压<br>实测抗剪弹性模量<br>实测抗压弹性模量</p>
      <p>检测周期:委外检测</p>
      <p>检测费用: 3000</p>
    </a-modal>
    <a-modal v-model="modal200" title='植筋胶' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>样品规格: 请电询苏州检测站</p>
    </a-modal>
    <a-modal v-model="modal201" title='混凝土界面处理剂' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数：参考标准:JC/T907-2018，附合格证质保书</p>
      <p>样品规格: 5kg</p>
    </a-modal>
    <a-modal v-model="modal202" title='玻化砖背胶(JCT 907-2018)' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>样品规格: 10kg</p>
    </a-modal>
    <a-modal v-model="modal203" title='水泥基渗透结晶型防水材料' :footer="null" centered >
      <p>类别：FT</p>
      <p>检测参数:抗压强度、抗折强度、湿基面、粘接强度</p>
      <p>送样数量:10kg</p>
      <p>检测费用:2000</p>
    </a-modal>
    <a-modal v-model="modal204" title='水性氟树脂涂料' :footer="null" centered >
      <p>类别：WW.S</p>
      <p>检测参数:HT/T 4104-2019</p>
      <p>样品规格:电询苏州</p>
    </a-modal>
    <a-modal v-model="modal205" title='铝合金建筑型材(门窗、幕墙用)' :footer="null" centered >
      <p>类别：XC</p>
      <p>检测参数:<br>壁厚(委托时写明:普通级|高精级|超高精级)<br>膜厚<br>膜厚平均厚度<br>膜厚最大局部厚度<br>膜厚最小局部厚度
      <br>韦氏硬度<br>横向抗拉<br>纵向抗剪</p>
      <p>样品规格:<br>60cm=2根<br>抗拉抗剪10cm30段(仅断桥，宽度不超8cm)<br>注:部分断桥型材截面尺寸过大无法完成力学检测，请按照下图要求加工样品(1的距离不包括断桥米)
      <br>委托时:提供型材壁厚值</p>
      <img src="../assets/img5.jpg">
      <p>代表数量:同批次同规格不限重量</p>
      <p>检测费用:常规390，力学1000</p>
    </a-modal>
    <a-modal v-model="modal206" title='门、窗用未增塑聚氯乙烯型材(塑钢)' :footer="null" centered >
      <p>类别：XC</p>
      <p>检测参数:<br>加热后尺寸变化<br>加热后状态<br>主型材落锤冲击试验<br>角强度试验</p>
      <p>样品规格:<br>角强度试验5个<br>10根30cm<br>3根25cm<br>3根20cm<br>其中角强度支撑面中心长度a为400+2mm，见下图</p>
      <img src="../assets/img6.png">
      <p>检测费用:1600</p>
    </a-modal>
    <a-modal v-model="modal207" title='现场-门窗' :footer="null" centered >
      <p>类别：XM</p>
      <p>检测参数:气密性<br>水密性</p>
      <p>检测费用:600</p>
    </a-modal>
    <a-modal v-model="modal208" title='细集料（基层用）' :footer="null" centered >
      <p>类别：XS</p>
      <p>检测参数: 筛分</p>
      <p>样品规格:20kg</p>
      <p>代表数量:600t或400m³或按进场批次实际量</p>
      <p>检测费用:筛分140</p>
    </a-modal>
    <a-modal v-model="modal209" title='烟道' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 垂直承载<br>抗柔性冲击</p>
      <p>样品规格:3长3短，共6段</p>
      <p>检测费用:1000</p>
    </a-modal>
    <a-modal v-model="modal210" title='泡沫混凝土/轻质混凝土' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 抗压、抗折</p>
      <p>样品规格:100*100*100=6块</p>
      <p>检测费用:抗压70;抗折90</p>
    </a-modal>
    <a-modal v-model="modal211" title='座浆料(试块，强度50以下)' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 抗压强度(检测标准:JGJ/T70-2009)</p>
      <p>样品规格: 标养<br>70.7mm³三块(钢模底)<br>70.7mm³六块(非钢模底)<br>仅试块，原材料暂不受理</p>
      <p>检测费用:600</p>
    </a-modal>
    <a-modal v-model="modal212" title='现场-建筑保温系统现场检测' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 屋顶传热阻、外墙传热阻、底层通风楼板传热阻、分户楼板传热阻</p>
      <p>检测费用:10000/点</p>
    </a-modal>
    <a-modal v-model="modal213" title='基础工程' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 承载力、低应变法、取芯法</p>
      <p>检测费用: 合同价</p>
    </a-modal>
    <a-modal v-model="modal214" title='热塑性聚烯烃(TPO)防水卷材' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 拉伸强度(纵向)<br>拉伸强度(横向)<br>断裂伸长率(纵向)<br>断裂伸长率(横向)<br>低温弯折性<br>不透水性
      <br>真角撕裂强度<br>参考标准:GB27789-2011，附合格证质保书</p>
      <p>样品规格: 2㎡</p>
      <p>代表数量: 10000㎡</p>
      <p>检测周期: 10工作日</p>
      <p>检测费用: H型:700:L型:580:P型:580</p>
    </a-modal>
    <a-modal v-model="modal215" title='泥灰碎石碎石含量' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 含泥量<br>碎石含量</p>
      <p>样品规格: 20kg</p>
      <p>检测费用: 220</p>
    </a-modal>
    <a-modal v-model="modal216" title='纳米陶瓷微珠保温隔热材料' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 在容器中状态<br>施工性<br>干燥时间<br>耐碱性<br>低温贮存稳定性<br>粘结强度(标准状态)<br>粘结强度(浸水后)</p>
      <p>样品规格: </p>
      <p>检测费用: 1480</p>
    </a-modal>
    <a-modal v-model="modal217" title='钢材断面收缩率' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 断面收缩率(厚度方向性能)</p>
      <p>样品规格: </p>
      <p>检测费用: 200</p>
    </a-modal>
    <a-modal v-model="modal218" title='混凝土轴心抗压强度' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 轴心抗压强度</p>
      <p>样品规格: 150*150*300mm 每组3块</p>
      <p>检测费用: 95(标养)</p>
    </a-modal>
    <a-modal v-model="modal219" title='混凝土劈抗拉强度' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 劈裂抗拉强度</p>
      <p>样品规格: </p>
      <p>检测费用: 280(标养)</p>
    </a-modal>
    <a-modal v-model="modal220" title='粒化高炉矿渣粉' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 活性指数(7d/28d)<br>密度<br>比表面积</p>
      <p>样品规格: </p>
      <p>检测费用: 1300</p>
    </a-modal>
    <a-modal v-model="modal221" title='聚合物砂浆' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 抗压</p>
      <p>样品规格: </p>
      <p>检测费用: 300</p>
    </a-modal>
    <a-modal v-model="modal222" title='粘钢粘结强度拉拔' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 粘结强度</p>
      <p>样品规格: </p>
      <p>检测费用: 1500</p>
    </a-modal>
    <a-modal v-model="modal223" title='挠度' :footer="null" centered >
      <p>类别：XX</p>
      <p>检测参数: 挠度</p>
      <p>样品规格: </p>
      <p>检测费用: 1000/点</p>
    </a-modal>
    <a-modal v-model="modal224" title='液塑限(土)' :footer="null" centered >
      <p>类别：YS</p>
      <p>检测参数: 界限含水率(液塑限)</p>
      <p>样品规格: 5斤</p>
      <p>检测费用: 260</p>
    </a-modal>
    <a-modal v-model="modal225" title='混凝土普通砖和装饰砖(承重)' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度</p>
      <p>样品规格: 15块;长度＜200=25块</p>
      <p>代表数量:150000</p>
      <p>检测周期:15个工作日</p>
      <p>检测费用: 抗压强度150，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal226" title='混凝土实心砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块;长度＜200mm=25块</p>
      <p>代表数量:100000</p>
      <p>检测费用: 抗压强度150，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal227" title='烧结保温砖和保温砌块(淤泥烧结保温砖和保温砌块)' :footer="null" centered >
      <p>类别：QK</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块</p>
      <p>检测费用: 抗压强度150，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal228" title='烧结空心砖和空心砌块' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块</p>
      <p>代表数量: 30000</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal229" title='蒸压灰砂实心砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度</p>
      <p>样品规格: 15块</p>
      <p>代表数量: 10万</p>
      <p>检测费用: 150</p>
    </a-modal>
    <a-modal v-model="modal230" title='混凝土普通砖和装饰砖(非承重)' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块;长度＜200mm=25块</p>
      <p>代表数量: 150000</p>
      <p>检测周期: 15个工作日</p>
      <p>检测费用: 抗压强度150，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal231" title='蒸压粉煤灰多孔砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度</p>
      <p>样品规格: 15块</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal232" title='承重混凝土多孔砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度</p>
      <p>样品规格: 15块</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal233" title='烧结多孔砖和多孔砌块(淤泥烧结多孔砖)' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块</p>
      <p>代表数量: 150000</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal234" title='蒸压灰砂多孔砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度</p>
      <p>样品规格: 15块</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal235" title='非承重混凝土空心砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块</p>
      <p>代表数量: 100000</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal236" title='非承重蒸压灰砂多孔砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>密度等级</p>
      <p>样品规格: 15块</p>
      <p>检测费用: 抗压强度100，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal237" title='蒸压粉煤灰砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度<br>抗折强度</p>
      <p>样品规格: 20块</p>
      <p>检测费用: 抗压强度150，抗折70，密度等级80</p>
    </a-modal>
    <a-modal v-model="modal238" title='烧结普通砖' :footer="null" centered >
      <p>类别：ZQ</p>
      <p>检测参数: 抗压强度</p>
      <p>样品规格: 15块</p>
      <p>代表数量:15万</p>
      <p>检测周期:10个工作日</p>
      <p>检测费用: 150</p>
    </a-modal>
    <a-modal v-model="modal239" title='聚氯乙烯弹性地板(卷材地板)' :footer="null" centered >
      <p>类别：ZX</p>
      <p>检测参数: 总挥发性有机物</p>
      <p>样品规格:100*100mm3块，写明基体材料(玻纤/非玻纤，发泡类/非发泡类)</p>
      <p>代表数量: 同一配方,工艺,规格,花色型号的卷材地板,≤5000㎡为一批</p>
      <p>检测费用: 1000</p>
    </a-modal>
    <a-modal v-model="modal240" title='细集料（沥青用）' :footer="null" centered >
      <p>类别：XS</p>
      <p>检测参数: 筛分<br>表观相对密度<br>亚甲蓝<br>砂当量<br>含泥量(天然砂)</p>
      <p>样品规格:20kg</p>
      <p>代表数量: 600t或400m³或按进场批次实际量</p>
      <p>检测费用: 筛分140，含泥量140,表观相对密度100、亚甲蓝300 砂当量320</p>
    </a-modal>

   
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     search:'',
     list:[
      {name:'胶粉聚苯颗粒保温砂浆',btn:'click1'},
      {name:'保温砂浆',btn:'click2'},
      {name:'幕墙玻璃',btn:'click3'},
      {name:'现场-建筑保温系统构造钻芯',btn:'click4'},
      {name:'外墙节能构造钻芯',btn:'click5'},
      {name:'承载率比',btn:'click6'},
      {name:'现场-建筑变形',btn:'click7'},
      {name:'现场-混凝土强度(回弹)',btn:'click8'},
      {name:'粗集料(基层用)',btn:'click9'},
      {name:'击实(无黏聚性粗巨粒土)',btn:'click10'},
      {name:'土(建筑材料放射性)',btn:'click11'},
      {name:'大理石(建筑材料放射性)',btn:'click12'},
      {name:'石膏板(建筑材料放射性)',btn:'click13'},
      {name:'砖瓦(建筑材料放射性)',btn:'click14'},
      {name:'无侧限抗压',btn:'click15'},
      {name:'插座',btn:'click16'},
      {name:'电工套管KBG/JDG',btn:'click17'},
      {name:'电工套管PVC',btn:'click18'},
      {name:'电工套管(金属电缆导管)',btn:'click19'},
      {name:'电缆',btn:'click20'},
      {name:'电线',btn:'click21'},
      {name:'自粘聚合物改性沥青防水卷材',btn:'click22'},
      {name:'预铺/湿铺防水卷材',btn:'click23'},
      {name:'聚合物乳液建筑防水涂料',btn:'click24'},
      {name:'聚氯乙烯防水卷材',btn:'click25'},
      {name:'弹/塑性体改性沥青防水卷材',btn:'click26'},
      {name:'聚氨酯防水涂料',btn:'click27'},
      {name:'防水卷材',btn:'click28'},
      {name:'高分子防水材料',btn:'click29'},
      {name:'聚合物水泥防水涂料',btn:'click30'},
      {name:'耐根穿刺防水卷材',btn:'click31'},
      {name:'现场-结构构件',btn:'click32'},
      {name:'UPVC螺旋消音管材',btn:'click33'},
      {name:'建筑排水用硬聚氩乙烯管材',btn:'click34'},
      {name:'玻璃纤维电缆管',btn:'click35'},
      {name:'给水用聚乙烯(PE)管材',btn:'click36'},
      {name:'埋地排水用钢带增强聚乙烯(PE)螺旋波纹管',btn:'click37'},
      {name:'PVC-U双壁波纹管材',btn:'click38'},
      {name:'高密度聚乙烯硅芯管',btn:'click39'},
      {name:'聚乙烯塑钢缠绕排水管',btn:'click40'},
      {name:'硬聚氬乙烯(UPVC),氯化聚氯乙烯(CPVC)塑料电缆导管(实壁类塑料电缆导管)',btn:'click41'},
      {name:'HDPE缠绕结构壁管材',btn:'click42'},
      {name:'地下通信管道用塑料实壁管',btn:'click43'},
      {name:'给水用抗冲改性聚氩乙烯管材',btn:'click44'},
      {name:'埋地通信用多孔一体塑料管材',btn:'click45'},
      {name:'MPP电力管',btn:'click46'},
      {name:'钢丝网骨架塑料复合管材',btn:'click47'},
      {name:'建筑用硬聚氩乙烯雨落水管材及管件',btn:'click48'},
      {name:'无压埋地排污、排水用硬聚氯乙烯管材',btn:'click49'},
      {name:'HDPE双壁波纹管材',btn:'click50'},
      {name:'非开挖铺设用高密度聚乙烯排水管',btn:'click51'},
      {name:'建筑排水用高密度聚乙烯(HDPE)管材',btn:'click52'},
      {name:'双层轴向中空壁管材',btn:'click53'},
      {name:'灌浆套筒连接接头',btn:'click54'},
      {name:'机械连接',btn:'click55'},
      {name:'热轧光圆钢筋',btn:'click56'},
      {name:'热轧带肋钢筋',btn:'click57'},
      {name:'热轧带肋钢筋(调直)',btn:'click58'},
      {name:'钢管、扣件(脚手架用)',btn:'click59'},
      {name:'电弧焊',btn:'click60'},
      {name:'闪光对焊',btn:'click61'},
      {name:'电渣压力焊',btn:'click62'},
      {name:'预埋件',btn:'click63'},
      {name:'钢筋网片',btn:'click64'},
      {name:'镀锌管',btn:'click65'},
      {name:'给水用硬聚氯乙烯管材',btn:'click66'},
      {name:'冷热水用聚丙烯(PP-R)管材',btn:'click67'},
      {name:'钢结构防火/防腐涂料 厚度',btn:'click68'},
      {name:'室内空气(有害物质)',btn:'click69'},
      {name:'灰剂量',btn:'click70'},
      {name:'混凝土试块抗压',btn:'click71'},
      {name:'混凝土配合比认证',btn:'click72'},
      {name:'混混凝土配合比',btn:'click73'},
      {name:'灰剂量曲线',btn:'click74'},
      {name:'砂(市政用)',btn:'click75'},
      {name:'管桩，方桩',btn:'click76'},
      {name:'混凝土预制板(叠合板;预制楼梯)',btn:'click77'},
      {name:'胶粘剂(粘结砂浆)',btn:'click78'},
      {name:'界面砂浆',btn:'click79'},
      {name:'涂料甲醛',btn:'click80'},
      {name:'涂料 VOC',btn:'click81'},
      {name:'人造板材(有害物质)',btn:'click82'},
      {name:'击实',btn:'click83'},
      {name:'水泥',btn:'click84'},
      {name:'矿粉(市政)',btn:'click85'},
      {name:'开关',btn:'click86'},
      {name:'混凝土试块抗渗',btn:'click87'},
      {name:'混凝土试块抗折',btn:'click88'},
      {name:'现场-保温锚固件',btn:'click89'},
      {name:'构造深度',btn:'click90'},
      {name:'窗三性',btn:'click91'},
      {name:'抗裂砂浆，抹面砂浆',btn:'click92'},
      {name:'沥青含量及矿料级配',btn:'click93'},
      {name:'马歇尔稳定度',btn:'click94'},
      {name:'沥青',btn:'click95'},
      {name:'钻芯(市政道路)',btn:'click96'},
      {name:'混凝土拌和物中水溶性氯离子含量',btn:'click97'},
      {name:'混凝土路缘石(平石，侧石)',btn:'click98'},
      {name:'混凝土路面砖',btn:'click99'},
      {name:'植草砖',btn:'click100'},
      {name:'透水砖',btn:'click101'},
      {name:'窗传热',btn:'click102'},
      {name:'现场-饰面砖、石材，碳纤维',btn:'click103'},
      {name:'现场-螺栓、植筋',btn:'click104'},
      {name:'抹灰砂浆粘结强度',btn:'click105'},
      {name:'硅酸钙板',btn:'click106'},
      {name:'电子交联发泡聚乙烯减震垫层',btn:'click107'},
      {name:'岩棉带',btn:'click108'},
      {name:'ZL矿物纤维喷涂层',btn:'click109'},
      {name:'DN保温隔声垫(软卷)',btn:'click110'},
      {name:'聚氨酯复合保温板',btn:'click111'},
      {name:'蒸压加气混凝土砌块，蒸压加气混凝土板(导热)',btn:'click112'},
      {name:'HKS保温隔声板',btn:'click113'},
      {name:'ALG纳米橡塑保温隔声垫(板)',btn:'click114'},
      {name:'橡塑保温板',btn:'click115'},
      {name:'复合材料保温板',btn:'click116'},
      {name:'保温腻子',btn:'click117'},
      {name:'泡沫混凝土(保温)',btn:'click118'},
      {name:'EHS纳米橡塑隔声保温板',btn:'click119'},
      {name:'FS-A保温板',btn:'click120'},
      {name:'EPS模塑聚苯板/黑泡高性能保温板',btn:'click121'},
      {name:'泡沫玻璃',btn:'click122'},
      {name:'保温装饰板(硅酸钙板+岩棉)',btn:'click123'},
      {name:'自保温砖',btn:'click124'},
      {name:'BGL地面隔声保温板',btn:'click125'},
      {name:'阻燃板',btn:'click126'},
      {name:'XPS挤塑聚苯板',btn:'click127'},
      {name:'发泡陶瓷保温板(泡沫玻璃)',btn:'click128'},
      {name:'岩棉板',btn:'click129'},
      {name:'石膏基无机保温砂浆',btn:'click130'},
      {name:'淤泥烧结保温砖',btn:'click131'},
      {name:'复合岩棉防火保温板',btn:'click132'},
      {name:'DN保温隔声板',btn:'click133'},
      {name:'抹灰石膏',btn:'click134'},
      {name:'现场-粉刷砂浆强度',btn:'click135'},
      {name:'现场-砌筑砂浆强度',btn:'click136'},
      {name:'蒸压加气混凝土砌块，蒸压加气混凝土板(原材)',btn:'click137'},
      {name:'普通混凝土小型空心砌块',btn:'click138'},
      {name:'混凝土空心砌块',btn:'click139'},
      {name:'现场-钻芯法测强度(结构取芯)',btn:'click140'},
      {name:'砂(土建用)',btn:'click141'},
      {name:'大理石、花岗岩',btn:'click142'},
      {name:'石灰',btn:'click143'},
      {name:'砂浆试块抗压',btn:'click144'},
      {name:'水泥砂浆、混合砂浆配合比',btn:'click145'},
      {name:'粉煤灰(道路用)',btn:'click146'},
      {name:'粉煤灰(混凝土用)',btn:'click147'},
      {name:'水泥土',btn:'click148'},
      {name:'碎石，卵石',btn:'click149'},
      {name:'瓷砖',btn:'click150'},
      {name:'压实度',btn:'click151'},
      {name:'土工布、塑料土工格栅、玻璃纤维土工格栅',btn:'click152'},
      {name:'合成树脂乳液内墙涂料',btn:'click153'},
      {name:'建筑内外墙用底漆',btn:'click154'},
      {name:'建筑外墙用腻子',btn:'click155'},
      {name:'合成树脂乳液外墙涂料',btn:'click156'},
      {name:'弹性建筑涂料',btn:'click157'},
      {name:'建筑室内用腻子',btn:'click158'},
      {name:'无机涂料',btn:'click159'},
      {name:'弹性模量',btn:'click160'},
      {name:'现场-闭水',btn:'click161'},
      {name:'土壤(有害物质)',btn:'click162'},
      {name:'现场-钢结构检测(探伤)',btn:'click163'},
      {name:'灌浆料(原材，强度50及以上)',btn:'click164'},
      {name:'灌浆料(试块，强度为50及以上)',btn:'click165'},
      {name:'混凝土瓦',btn:'click166'},
      {name:'烧结瓦',btn:'click167'},
      {name:'现场-道路弯沉',btn:'click168'},
      {name:'混凝土外加剂',btn:'click169'},
      {name:'高分子乳液改性沥青防水涂料',btn:'click170'},
      {name:'预应力孔道压浆料',btn:'click171'},
      {name:'水性多彩涂料',btn:'click172'},
      {name:'胶粘剂(有害物质)',btn:'click173'},
      {name:'真石漆',btn:'click174'},
      {name:'钢筋砼管',btn:'click175'},
      {name:'建筑用硅酮结构胶',btn:'click176'},
      {name:'止水带,止水条',btn:'click177'},
      {name:'建筑用硅酮密封胶',btn:'click178'},
      {name:'输送流体用无缝钢管',btn:'click179'},
      {name:'混凝土排水管(有筋)',btn:'click180'},
      {name:'合成树脂乳液砂壁状建筑涂料(JGT24)',btn:'click181'},
      {name:'环氧沥青防腐涂料',btn:'click182'},
      {name:'钢结构防火/防腐涂料 原材',btn:'click183'},
      {name:'地暖管(冷热水用耐热聚乙烯PE-RT管道系统)',btn:'click184'},
      {name:'不锈钢管',btn:'click185'},
      {name:'溶剂型涂料VOC',btn:'click186'},
      {name:'钢网架螺栓球接点用高强度螺栓',btn:'click187'},
      {name:'复层建筑涂料',btn:'click188'},
      {name:'橡塑保温管',btn:'click189'},
      {name:'粘钢胶',btn:'click190'},
      {name:'反射隔热涂料',btn:'click191'},
      {name:'玻璃棉(原材)',btn:'click192'},
      {name:'非固化橡胶沥青防水涂料',btn:'click193'},
      {name:'预应力钢绞线',btn:'click194'},
      {name:'矿物质电缆',btn:'click195'},
      {name:'纤维水泥板',btn:'click196'},
      {name:'玻璃棉(燃烧)',btn:'click197'},
      {name:'粗集料(沥青用)',btn:'click198'},
      {name:'板式橡胶支座',btn:'click199'},
      {name:'植筋胶',btn:'click200'},
      {name:'混凝土界面处理剂',btn:'click201'},
      {name:'玻化砖背胶(JCT 907-2018)',btn:'click202'},
      {name:'水泥基渗透结晶型防水材料',btn:'click203'},
      {name:'水性氟树脂涂料',btn:'click204'},
      {name:'铝合金建筑型材(门窗、幕墙用)',btn:'click205'},
      {name:'门、窗用未增塑聚氯乙烯型材(塑钢)',btn:'click206'},
      {name:'现场-门窗',btn:'click207'},
      {name:'细集料（基层用）',btn:'click208'},
      {name:'烟道',btn:'click209'},
      {name:'泡沫混凝土/轻质混凝土',btn:'click210'},
      {name:'座浆料(试块，强度50以下)',btn:'click211'},
      {name:'现场-建筑保温系统现场检测',btn:'click212'},
      {name:'基础工程',btn:'click213'},
      {name:'热塑性聚烯烃(TPO)防水卷材',btn:'click214'},
      {name:'泥灰碎石碎石含量',btn:'click215'},
      {name:'纳米陶瓷微珠保温隔热材料',btn:'click216'},
      {name:'钢材断面收缩率',btn:'click217'},
      {name:'混凝土轴心抗压强度',btn:'click218'},
      {name:'混凝土劈抗拉强度',btn:'click219'},
      {name:'粒化高炉矿渣粉',btn:'click220'},
      {name:'聚合物砂浆',btn:'click221'},
      {name:'粘钢粘结强度拉拔',btn:'click222'},
      {name:'挠度',btn:'click223'},
      {name:'液塑限(土)',btn:'click224'},
      {name:'混凝土普通砖和装饰砖(承重)',btn:'click225'},
      {name:'混凝土实心砖',btn:'click226'},
      {name:'烧结保温砖和保温砌块(淤泥烧结保温砖和保温砌块)',btn:'click227'},
      {name:'烧结空心砖和空心砌块',btn:'click228'},
      {name:'蒸压灰砂实心砖',btn:'click229'},
      {name:'混凝土普通砖和装饰砖(非承重)',btn:'click230'},
      {name:'蒸压粉煤灰多孔砖',btn:'click231'},
      {name:'承重混凝土多孔砖',btn:'click232'},
      {name:'烧结多孔砖和多孔砌块(淤泥烧结多孔砖)',btn:'click233'},
      {name:'蒸压灰砂多孔砖',btn:'click234'},
      {name:'非承重混凝土空心砖',btn:'click235'},
      {name:'非承重蒸压灰砂多孔砖',btn:'click236'},
      {name:'蒸压粉煤灰砖',btn:'click237'},
      {name:'烧结普通砖',btn:'click238'},
      {name:'聚氯乙烯弹性地板(卷材地板)',btn:'click239'},
      {name:'细集料（沥青用）',btn:'click240'},
     ],
     modal1:false,
     modal2:false,
     modal3:false,
     modal4:false,
     modal5:false,
     modal6:false,
     modal7:false,
     modal8:false,
     modal9:false,
     modal10:false,
     modal11:false,
     modal12:false,
     modal13:false,
     modal14:false,
     modal15:false,
     modal16:false,
     modal17:false,
     modal18:false,
     modal19:false,
     modal20:false,
     modal21:false,
     modal22:false,
     modal23:false,
     modal24:false,
     modal25:false,
     modal26:false,
     modal27:false,
     modal28:false,
     modal29:false,
     modal30:false,
     modal31:false,
     modal32:false,
     modal33:false,
     modal34:false,
     modal35:false,
     modal36:false,
     modal37:false,
     modal38:false,
     modal39:false,
     modal40:false,
     modal41:false,
     modal42:false,
     modal43:false,
     modal44:false,
     modal45:false,
     modal46:false,
     modal47:false,
     modal48:false,
     modal49:false,
     modal50:false,
     modal51:false,
     modal52:false,
     modal53:false,
     modal54:false,
     modal55:false,
     modal56:false,
     modal57:false,
     modal58:false,
     modal59:false,
     modal60:false,
     modal61:false,
     modal62:false,
     modal63:false,
     modal64:false,
     modal65:false,
     modal66:false,
     modal67:false,
     modal68:false,
     modal69:false,
     modal70:false,
     modal71:false,
     modal72:false,
     modal73:false,
     modal74:false,
     modal75:false,
     modal76:false,
     modal77:false,
     modal78:false,
     modal79:false,
     modal80:false,
     modal81:false,
     modal82:false,
     modal83:false,
     modal84:false,
     modal85:false,
     modal86:false,
     modal87:false,
     modal88:false,
     modal89:false,
     modal90:false,
     modal91:false,
     modal92:false,
     modal93:false,
     modal94:false,
     modal95:false,
     modal96:false,
     modal97:false,
     modal98:false,
     modal99:false,
     modal100:false,
     modal101:false,
     modal102:false,
     modal103:false,
     modal104:false,
     modal105:false,
     modal106:false,
     modal107:false,
     modal108:false,
     modal109:false,
     modal110:false,
     modal111:false,
     modal112:false,
     modal113:false,
     modal114:false,
     modal115:false,
     modal116:false,
     modal117:false,
     modal118:false,
     modal119:false,
     modal120:false,
     modal121:false,
     modal122:false,
     modal123:false,
     modal124:false,
     modal125:false,
     modal126:false,
     modal127:false,
     modal128:false,
     modal129:false,
     modal130:false,
     modal131:false,
     modal132:false,
     modal133:false,
     modal134:false,
     modal135:false,
     modal136:false,
     modal137:false,
     modal138:false,
     modal139:false,
     modal140:false,
     modal141:false,
     modal142:false,
     modal143:false,
     modal144:false,
     modal145:false,
     modal146:false,
     modal147:false,
     modal148:false,
     modal149:false,
     modal150:false,
     modal151:false,
     modal152:false,
     modal153:false,
     modal154:false,
     modal155:false,
     modal156:false,
     modal157:false,
     modal158:false,
     modal159:false,
     modal160:false,
     modal161:false,
     modal162:false,
     modal163:false,
     modal164:false,
     modal165:false,
     modal166:false,
     modal167:false,
     modal168:false,
     modal169:false,
     modal170:false,
     modal171:false,
     modal172:false,
     modal173:false,
     modal174:false,
     modal175:false,
     modal176:false,
     modal177:false,
     modal178:false,
     modal179:false,
     modal180:false,
     modal181:false,
     modal182:false,
     modal183:false,
     modal184:false,
     modal185:false,
     modal186:false,
     modal187:false,
     modal188:false,
     modal189:false,
     modal190:false,
     modal191:false,
     modal192:false,
     modal193:false,
     modal194:false,
     modal195:false,
     modal196:false,
     modal197:false,
     modal198:false,
     modal199:false,
     modal200:false,
     modal201:false,
     modal202:false,
     modal203:false,
     modal204:false,
     modal205:false,
     modal206:false,
     modal207:false,
     modal208:false,
     modal209:false,
     modal210:false,
     modal211:false,
     modal212:false,
     modal213:false,
     modal214:false,
     modal215:false,
     modal216:false,
     modal217:false,
     modal218:false,
     modal219:false,
     modal220:false,
     modal221:false,
     modal222:false,
     modal223:false,
     modal224:false,
     modal225:false,
     modal226:false,
     modal227:false,
     modal228:false,
     modal229:false,
     modal230:false,
     modal231:false,
     modal232:false,
     modal233:false,
     modal234:false,
     modal235:false,
     modal236:false,
     modal237:false,
     modal238:false,
     modal239:false,
     modal240:false,
     
    };
  },
  computed:{
    getSearch(){
      if(this.search){
        return this.list.filter((item)=>item.name.includes(this.search))
      }else{
        return this.list
      }
    }
  },
  methods: {
    zhixing(btn){
      this[btn]()
    },
    click1(){
      this.modal1=true
    },click2(){
      this.modal2=true
    },click3(){
      this.modal3=true
    },click4(){
      this.modal4=true
    },click5(){
      this.modal5=true
    },click6(){
      this.modal6=true
    },click7(){
      this.modal7=true
    },click8(){
      this.modal8=true
    },click9(){
      this.modal9=true
    },click10(){
      this.modal10=true
    },click11(){
      this.modal11=true
    },click12(){
      this.modal12=true
    },click13(){
      this.modal13=true
    },click14(){
      this.modal14=true
    },click15(){
      this.modal15=true
    },click16(){
      this.modal16=true
    },click17(){
      this.modal17=true
    },click18(){
      this.modal18=true
    },click19(){
      this.modal19=true
    },click20(){
      this.modal20=true
    },click21(){
      this.modal21=true
    },click22(){
      this.modal22=true
    },click23(){
      this.modal23=true
    },click24(){
      this.modal24=true
    },click25(){
      this.modal25=true
    },click26(){
      this.modal26=true
    },click27(){
      this.modal27=true
    },click28(){
      this.modal28=true
    },click29(){
      this.modal29=true
    },click30(){
      this.modal30=true
    },click31(){
      this.modal31=true
    },click32(){
      this.modal32=true
    },click33(){
      this.modal33=true
    },click34(){
      this.modal34=true
    },click35(){
      this.modal35=true
    },click36(){
      this.modal36=true
    },click37(){
      this.modal37=true
    },click38(){
      this.modal38=true
    },click39(){
      this.modal39=true
    },click40(){
      this.modal40=true
    },click41(){
      this.modal41=true
    },click42(){
      this.modal42=true
    },click43(){
      this.modal43=true
    },click44(){
      this.modal44=true
    },click45(){
      this.modal45=true
    },click46(){
      this.modal46=true
    },clic47(){
      this.modal47=true
    },click48(){
      this.modal48=true
    },click49(){
      this.modal49=true
    },click50(){
      this.modal50=true
    },click51(){
      this.modal51=true
    },click52(){
      this.modal52=true
    },click53(){
      this.modal53=true
    },click54(){
      this.modal54=true
    },click55(){
      this.modal55=true
    },click56(){
      this.modal56=true
    },click57(){
      this.modal57=true
    },click58(){
      this.modal58=true
    },click59(){
      this.modal59=true
    },click60(){
      this.modal60=true
    },click61(){
      this.modal61=true
    },click62(){
      this.modal62=true
    },click63(){
      this.modal63=true
    },click64(){
      this.modal64=true
    },click65(){
      this.modal65=true
    },click66(){
      this.modal66=true
    },click67(){
      this.modal67=true
    },click68(){
      this.modal68=true
    },click69(){
      this.modal69=true
    },click70(){
      this.modal70=true
    },click71(){
      this.modal71=true
    },click72(){
      this.modal72=true
    },click73(){
      this.modal73=true
    },click74(){
      this.modal74=true
    },click75(){
      this.modal75=true
    },click76(){
      this.modal76=true
    },click77(){
      this.modal77=true
    },click78(){
      this.modal78=true
    },click79(){
      this.modal79=true
    },click80(){
      this.modal80=true
    },click81(){
      this.modal81=true
    },click82(){
      this.modal82=true
    },click83(){
      this.modal83=true
    },click84(){
      this.modal84=true
    },click85(){
      this.modal85=true
    },click86(){
      this.modal86=true
    },click87(){
      this.modal87=true
    },click88(){
      this.modal88=true
    },click89(){
      this.modal89=true
    },click90(){
      this.modal90=true
    },click91(){
      this.modal91=true
    },click92(){
      this.modal92=true
    },click93(){
      this.modal93=true
    },click94(){
      this.modal94=true
    },click95(){
      this.modal95=true
    },click96(){
      this.modal96=true
    },click97(){
      this.modal97=true
    },click98(){
      this.modal98=true
    },click99(){
      this.modal99=true
    },click100(){
      this.modal100=true
    },click101(){
      this.modal101=true
    },click102(){
      this.modal102=true
    },click103(){
      this.modal103=true
    },click104(){
      this.modal104=true
    },click105(){
      this.modal105=true
    },click106(){
      this.modal106=true
    },click107(){
      this.modal107=true
    },click108(){
      this.modal108=true
    },click109(){
      this.modal109=true
    },click110(){
      this.modal110=true
    },click111(){
      this.modal111=true
    },click112(){
      this.modal112=true
    },click113(){
      this.modal113=true
    },click114(){
      this.modal114=true
    },click115(){
      this.modal115=true
    },click116(){
      this.modal116=true
    },click117(){
      this.modal117=true
    },click118(){
      this.modal118=true
    },click119(){
      this.modal119=true
    },click120(){
      this.modal120=true
    },click121(){
      this.modal121=true
    },click122(){
      this.modal122=true
    },click123(){
      this.modal123=true
    },click124(){
      this.modal124=true
    },click125(){
      this.modal125=true
    },click126(){
      this.modal126=true
    },click127(){
      this.modal127=true
    },click128(){
      this.modal128=true
    },click129(){
      this.modal129=true
    },click130(){
      this.modal130=true
    },click131(){
      this.modal131=true
    },click132(){
      this.modal132=true
    },click133(){
      this.modal133=true
    },click134(){
      this.modal134=true
    },click135(){
      this.modal135=true
    },click136(){
      this.modal136=true
    },click137(){
      this.modal137=true
    },click138(){
      this.modal138=true
    },click139(){
      this.modal139=true
    },click140(){
      this.modal140=true
    },click141(){
      this.modal141=true
    },click142(){
      this.modal142=true
    },click143(){
      this.modal143=true
    },click144(){
      this.modal144=true
    },click145(){
      this.modal145=true
    },click146(){
      this.modal146=true
    },clic147(){
      this.modal147=true
    },click148(){
      this.modal148=true
    },click149(){
      this.modal149=true
    },click150(){
      this.modal150=true
    },click151(){
      this.modal151=true
    },click152(){
      this.modal152=true
    },click153(){
      this.modal153=true
    },click154(){
      this.modal154=true
    },click155(){
      this.modal155=true
    },click156(){
      this.modal156=true
    },click157(){
      this.modal157=true
    },click158(){
      this.modal158=true
    },click159(){
      this.modal159=true
    },click160(){
      this.modal160=true
    },click161(){
      this.modal161=true
    },click162(){
      this.modal162=true
    },click163(){
      this.modal163=true
    },click164(){
      this.modal164=true
    },click165(){
      this.modal165=true
    },click166(){
      this.modal166=true
    },click167(){
      this.modal167=true
    },click168(){
      this.modal168=true
    },click169(){
      this.modal169=true
    },click170(){
      this.modal170=true
    },click171(){
      this.modal171=true
    },click172(){
      this.modal172=true
    },click173(){
      this.modal173=true
    },click174(){
      this.modal174=true
    },click175(){
      this.modal175=true
    },click176(){
      this.modal176=true
    },click177(){
      this.modal177=true
    },click178(){
      this.modal178=true
    },click179(){
      this.modal179=true
    },click180(){
      this.modal180=true
    },click181(){
      this.modal181=true
    },click182(){
      this.modal182=true
    },click183(){
      this.modal183=true
    },click184(){
      this.modal184=true
    },click185(){
      this.modal185=true
    },click186(){
      this.modal186=true
    },click187(){
      this.modal187=true
    },click188(){
      this.modal188=true
    },click189(){
      this.modal189=true
    },click190(){
      this.modal190=true
    },click191(){
      this.modal191=true
    },click192(){
      this.modal192=true
    },click193(){
      this.modal193=true
    },click194(){
      this.modal194=true
    },click195(){
      this.modal195=true
    },click196(){
      this.modal196=true
    },click197(){
      this.modal197=true
    },click198(){
      this.modal198=true
    },click199(){
      this.modal199=true
    },click200(){
      this.modal200=true
    },
    click201(){
      this.modal201=true
    },click202(){
      this.modal202=true
    },click203(){
      this.modal203=true
    },click204(){
      this.modal204=true
    },click205(){
      this.modal205=true
    },click206(){
      this.modal206=true
    },click207(){
      this.modal207=true
    },click208(){
      this.modal208=true
    },click209(){
      this.modal209=true
    },click210(){
      this.modal210=true
    },click211(){
      this.modal211=true
    },click212(){
      this.modal212=true
    },click213(){
      this.modal213=true
    },click214(){
      this.modal214=true
    },click215(){
      this.modal215=true
    },click216(){
      this.modal216=true
    },click217(){
      this.modal217=true
    },click218(){
      this.modal218=true
    },click219(){
      this.modal219=true
    },click220(){
      this.modal220=true
    },click221(){
      this.modal221=true
    },click222(){
      this.modal222=true
    },click223(){
      this.modal223=true
    },click224(){
      this.modal224=true
    },click225(){
      this.modal225=true
    },click226(){
      this.modal226=true
    },click227(){
      this.modal227=true
    },click228(){
      this.modal228=true
    },click229(){
      this.modal229=true
    },click230(){
      this.modal230=true
    },click231(){
      this.modal231=true
    },click232(){
      this.modal232=true
    },click233(){
      this.modal233=true
    },click234(){
      this.modal234=true
    },click235(){
      this.modal235=true
    },click236(){
      this.modal236=true
    },click237(){
      this.modal237=true
    },click238(){
      this.modal238=true
    },click239(){
      this.modal239=true
    },click240(){
      this.modal240=true
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
  color: #40a9ff;
}
.btn{
  width: 370px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  text-align: left;
  margin-bottom: 8px;
  white-space:pre-wrap
}
.btn46{
  height:50px
}
.box{
  display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
}
img{
  width:300px;
}
</style>
